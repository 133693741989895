import { FC } from 'react'
import { Icon } from 'ui/icons'
import { IconProps } from 'ui/icons/Icon'

const Instagram: FC<IconProps> = ({
  originalWidth,
  originalHeight,
  color,
  ...props
}) => (
  <Icon
    originalHeight={originalHeight}
    originalWidth={originalWidth}
    {...props}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M3.5931 6.01534C3.46487 7.30993 3.36051 8.82878 3.36051 10.3333C3.36051 11.7207 3.4493 13.0335 3.5629 14.1436C3.68806 15.3666 4.63905 16.3159 5.90293 16.4457C7.16137 16.5748 8.64053 16.6803 10.1051 16.6803C11.5036 16.6803 12.8929 16.5841 14.0843 16.4631C15.3563 16.3338 16.3366 15.3526 16.4666 14.07C16.5924 12.829 16.6938 11.3806 16.6938 9.94649C16.6938 8.54275 16.5967 7.14515 16.4747 5.94738C16.3453 4.67648 15.3678 3.69888 14.0924 3.56848C12.8877 3.4453 11.4893 3.34696 10.1051 3.34696C8.70811 3.34696 7.27305 3.4471 6.03037 3.57206C4.72653 3.70318 3.7225 4.70889 3.5931 6.01534ZM1.93455 5.85106C1.80289 7.18033 1.69385 8.75701 1.69385 10.3333C1.69385 11.7895 1.78691 13.1604 1.90489 14.3133C2.11258 16.3427 3.7034 17.8953 5.73275 18.1036C7.02698 18.2365 8.56608 18.347 10.1051 18.347C11.5757 18.347 13.0237 18.2461 14.2528 18.1212C16.3174 17.9114 17.9155 16.3028 18.1248 14.2381C18.2542 12.9614 18.3605 11.4539 18.3605 9.94649C18.3605 8.47033 18.2586 7.0138 18.1328 5.77855C17.9231 3.71872 16.3217 2.12105 14.262 1.91046C13.0209 1.78357 11.5631 1.6803 10.1051 1.6803C8.63448 1.6803 7.1404 1.78536 5.86361 1.91376C3.76905 2.12439 2.14204 3.75619 1.93455 5.85106Z'
      fill='#10142D'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M1.69385 10.3333C1.69385 8.75701 1.80289 7.18032 1.93455 5.85106C2.14206 3.75603 3.76921 2.12438 5.86361 1.91376C7.14039 1.78536 8.63447 1.6803 10.1051 1.6803C11.5631 1.6803 13.0209 1.78357 14.262 1.91046C16.322 2.12108 17.9231 3.71902 18.1328 5.77855C18.1485 5.93296 18.1639 6.09082 18.1787 6.25175C18.2825 7.37823 18.3605 8.65484 18.3605 9.94649C18.3605 11.4539 18.2542 12.9614 18.1248 14.2381C18.1117 14.3672 18.0932 14.4945 18.0695 14.6198C18.0257 14.8508 17.9643 15.075 17.8866 15.2909C17.7536 15.6603 17.573 16.0055 17.3518 16.3191C17.0894 16.6911 16.77 17.0188 16.4052 17.29C15.9906 17.5985 15.5174 17.834 15.0031 17.9788C14.7615 18.0469 14.5107 18.095 14.2528 18.1212C13.0237 18.2461 11.5757 18.347 10.1051 18.347C8.56607 18.347 7.02697 18.2365 5.73275 18.1036C3.70057 17.895 2.1123 16.3399 1.90489 14.3133C1.78691 13.1604 1.69385 11.7895 1.69385 10.3333ZM3.5931 6.01534C3.46487 7.30993 3.36051 8.82878 3.36051 10.3333C3.36051 11.7207 3.4493 13.0335 3.5629 14.1436C3.68806 15.3666 4.63905 16.3159 5.90293 16.4457C7.16137 16.5748 8.64053 16.6803 10.1051 16.6803C11.5036 16.6803 12.8929 16.5841 14.0843 16.4631C15.3563 16.3338 16.3366 15.3526 16.4666 14.07C16.5924 12.829 16.6938 11.3806 16.6938 9.94649C16.6938 8.54275 16.5967 7.14515 16.4747 5.94738C16.4585 5.78852 16.4291 5.63424 16.3875 5.48562C16.0963 4.44532 15.2084 3.68258 14.0924 3.56848C12.8877 3.4453 11.4893 3.34696 10.1051 3.34696C8.70811 3.34696 7.27305 3.4471 6.03037 3.57206C4.72653 3.70318 3.7225 4.70889 3.5931 6.01534Z'
      fill='#10142D'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M9.99992 12.5C11.3806 12.5 12.4999 11.3808 12.4999 10C12.4999 8.61933 11.3806 7.50004 9.99992 7.50004C8.61921 7.50004 7.49992 8.61933 7.49992 10C7.49992 11.3808 8.61921 12.5 9.99992 12.5ZM14.1666 10C14.1666 12.3012 12.3011 14.1667 9.99992 14.1667C7.69873 14.1667 5.83325 12.3012 5.83325 10C5.83325 7.69885 7.69873 5.83337 9.99992 5.83337C12.3011 5.83337 14.1666 7.69885 14.1666 10Z'
      fill='#10142D'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M14.1666 10C14.1666 12.3012 12.3011 14.1667 9.99992 14.1667C7.69873 14.1667 5.83325 12.3012 5.83325 10C5.83325 7.69885 7.69873 5.83337 9.99992 5.83337C12.3011 5.83337 14.1666 7.69885 14.1666 10ZM9.99992 12.5C11.3806 12.5 12.4999 11.3808 12.4999 10C12.4999 8.61933 11.3806 7.50004 9.99992 7.50004C8.61921 7.50004 7.49992 8.61933 7.49992 10C7.49992 11.3808 8.61921 12.5 9.99992 12.5Z'
      fill='#10142D'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M14.5272 6.01359C14.9874 6.01359 15.3605 5.64049 15.3605 5.18026C15.3605 4.72002 14.9874 4.34692 14.5272 4.34692C14.3053 4.34692 14.1037 4.43362 13.9544 4.57499C13.7939 4.72688 13.6938 4.94188 13.6938 5.18026C13.6938 5.64049 14.0669 6.01359 14.5272 6.01359Z'
      fill='#10142D'
    />
  </Icon>
)

Instagram.defaultProps = {
  originalWidth: 20,
  originalHeight: 20,
  width: 20,
  height: 20,
}

export default Instagram
