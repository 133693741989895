import { FC } from 'react'
import { Icon } from 'ui/icons'
import { IconProps } from 'ui/icons/Icon'

const AppStore: FC<IconProps> = ({
  originalWidth,
  originalHeight,
  color,
  ...props
}) => (
  <Icon
    originalHeight={originalHeight}
    originalWidth={originalWidth}
    {...props}
  >
    <path
      d='M104 0H4C1.79086 0 0 1.79086 0 4V28C0 30.2091 1.79086 32 4 32H104C106.209 32 108 30.2091 108 28V4C108 1.79086 106.209 0 104 0Z'
      fill='black'
    />
    <path
      d='M25.6902 15.4504C25.6681 12.9937 27.7017 11.7985 27.7947 11.7428C26.643 10.0636 24.8578 9.83418 24.2305 9.81589C22.7311 9.65811 21.2768 10.7131 20.513 10.7131C19.734 10.7131 18.5579 9.83114 17.2902 9.85705C15.659 9.88221 14.133 10.8266 13.2961 12.2932C11.5688 15.2835 12.857 19.6778 14.5119 22.0949C15.3397 23.2787 16.3069 24.6004 17.573 24.5539C18.8117 24.5028 19.2744 23.7642 20.7691 23.7642C22.2502 23.7642 22.6847 24.5539 23.9759 24.5242C25.3052 24.5028 26.1422 23.3351 26.941 22.1406C27.8976 20.7838 28.2818 19.4476 28.2971 19.379C28.2658 19.3683 25.7153 18.395 25.6902 15.4504Z'
      fill='white'
    />
    <path
      d='M23.2511 8.22595C23.9173 7.39282 24.3732 6.25936 24.2466 5.10913C23.2824 5.15182 22.0765 5.7761 21.3821 6.59094C20.7677 7.30897 20.2189 8.48588 20.3607 9.59266C21.4438 9.67345 22.556 9.04613 23.2511 8.22595Z'
      fill='white'
    />
    <path
      d='M43.6156 20.2928H41.8845L40.9363 17.3131H37.6403L36.7371 20.2928H35.0518L38.3172 10.1488H40.3341L43.6156 20.2928ZM40.6504 16.0631L39.7929 13.4143C39.7022 13.1437 39.5322 12.5064 39.2815 11.5033H39.251C39.1511 11.9347 38.9903 12.572 38.7692 13.4143L37.9269 16.0631H40.6504Z'
      fill='white'
    />
    <path
      d='M52.0129 16.5456C52.0129 17.7896 51.6767 18.7729 51.0044 19.4947C50.4023 20.1373 49.6545 20.4582 48.7619 20.4582C47.7984 20.4582 47.1063 20.1121 46.6848 19.42H46.6543V23.2732H45.0292V15.3862C45.0292 14.6042 45.0086 13.8015 44.969 12.9783H46.3982L46.4889 14.1377H46.5194C47.0614 13.2641 47.8838 12.8281 48.9875 12.8281C49.8504 12.8281 50.5707 13.1688 51.147 13.8511C51.7248 14.534 52.0129 15.432 52.0129 16.5456ZM50.3573 16.605C50.3573 15.8931 50.1972 15.3062 49.8756 14.8443C49.5242 14.3625 49.0523 14.1217 48.4608 14.1217C48.0599 14.1217 47.6955 14.2558 47.3701 14.5203C47.0438 14.7871 46.8304 15.1354 46.7305 15.5669C46.6802 15.7681 46.6551 15.9327 46.6551 16.0623V17.2819C46.6551 17.814 46.8182 18.2629 47.1444 18.6296C47.4707 18.9962 47.8945 19.1791 48.4159 19.1791C49.0279 19.1791 49.5044 18.9429 49.8451 18.4718C50.1866 18 50.3573 17.378 50.3573 16.605Z'
      fill='white'
    />
    <path
      d='M60.426 16.5456C60.426 17.7896 60.0899 18.7729 59.4168 19.4947C58.8154 20.1373 58.0676 20.4582 57.175 20.4582C56.2116 20.4582 55.5194 20.1121 55.0987 19.42H55.0682V23.2732H53.4431V15.3862C53.4431 14.6042 53.4225 13.8015 53.3829 12.9783H54.8121L54.9028 14.1377H54.9333C55.4745 13.2641 56.2969 12.8281 57.4014 12.8281C58.2635 12.8281 58.9839 13.1688 59.5616 13.8511C60.1371 14.534 60.426 15.432 60.426 16.5456ZM58.7704 16.605C58.7704 15.8931 58.6096 15.3062 58.2879 14.8443C57.9365 14.3625 57.4662 14.1217 56.874 14.1217C56.4723 14.1217 56.1087 14.2558 55.7824 14.5203C55.4562 14.7871 55.2435 15.1354 55.1437 15.5669C55.0941 15.7681 55.0682 15.9327 55.0682 16.0623V17.2819C55.0682 17.814 55.2313 18.2629 55.556 18.6296C55.8823 18.9954 56.3061 19.1791 56.829 19.1791C57.4411 19.1791 57.9175 18.9429 58.2582 18.4718C58.5997 18 58.7704 17.378 58.7704 16.605Z'
      fill='white'
    />
    <path
      d='M69.8324 17.4481C69.8324 18.311 69.5328 19.013 68.9314 19.555C68.2706 20.1472 67.3505 20.443 66.1683 20.443C65.0768 20.443 64.2017 20.2326 63.5393 19.8111L63.9159 18.4566C64.6293 18.888 65.4121 19.1045 66.2651 19.1045C66.8772 19.1045 67.3536 18.9658 67.6958 18.6898C68.0366 18.4139 68.2065 18.0435 68.2065 17.5815C68.2065 17.1699 68.0663 16.8231 67.785 16.5418C67.5053 16.2606 67.038 15.9991 66.3855 15.7575C64.6095 15.0951 63.7222 14.1248 63.7222 12.8488C63.7222 12.0149 64.0332 11.3311 64.656 10.7991C65.2765 10.2663 66.1043 10.0002 67.1394 10.0002C68.0625 10.0002 68.8293 10.1611 69.4414 10.482L69.0351 11.8068C68.4634 11.4958 67.817 11.3403 67.0937 11.3403C66.522 11.3403 66.0753 11.4813 65.7552 11.7618C65.4846 12.0126 65.3489 12.3182 65.3489 12.6803C65.3489 13.0812 65.5036 13.4128 65.8146 13.6735C66.0852 13.9144 66.5769 14.1751 67.2903 14.4563C68.1631 14.8077 68.8041 15.2186 69.2165 15.6896C69.6274 16.1592 69.8324 16.7469 69.8324 17.4481Z'
      fill='white'
    />
    <path
      d='M75.2052 14.198H73.4139V17.7493C73.4139 18.6526 73.7295 19.1038 74.3621 19.1038C74.6525 19.1038 74.8934 19.0787 75.084 19.0283L75.1289 20.2624C74.8088 20.3821 74.3873 20.4423 73.8651 20.4423C73.2233 20.4423 72.7218 20.2464 72.3597 19.8554C71.9992 19.4636 71.8177 18.8065 71.8177 17.8835V14.1965H70.7506V12.9769H71.8177V11.6376L73.4139 11.1559V12.9769H75.2052V14.198Z'
      fill='white'
    />
    <path
      d='M83.2873 16.5753C83.2873 17.6996 82.9657 18.6227 82.3239 19.3445C81.6508 20.0877 80.7574 20.4582 79.6438 20.4582C78.5706 20.4582 77.7161 20.1022 77.0788 19.3903C76.4416 18.6783 76.123 17.7796 76.123 16.6965C76.123 15.563 76.4508 14.6346 77.1086 13.9128C77.7649 13.1902 78.6506 12.8289 79.7642 12.8289C80.8375 12.8289 81.7011 13.1848 82.3528 13.8975C82.9763 14.5889 83.2873 15.4815 83.2873 16.5753ZM81.6012 16.6279C81.6012 15.9533 81.4572 15.3748 81.1652 14.8923C80.8245 14.3084 80.3374 14.0172 79.7063 14.0172C79.0531 14.0172 78.5568 14.3091 78.2161 14.8923C77.9242 15.3755 77.7801 15.9632 77.7801 16.6584C77.7801 17.333 77.9242 17.9115 78.2161 18.3932C78.5675 18.9771 79.0584 19.2683 79.6918 19.2683C80.3123 19.2683 80.7994 18.971 81.1508 18.378C81.4503 17.8864 81.6012 17.3017 81.6012 16.6279Z'
      fill='white'
    />
    <path
      d='M88.5696 14.4075C88.4087 14.3778 88.2372 14.3625 88.0573 14.3625C87.4856 14.3625 87.0435 14.5782 86.7325 15.0104C86.4619 15.3916 86.3263 15.8733 86.3263 16.4549V20.2928H84.7019L84.7172 15.2818C84.7172 14.4387 84.6966 13.6712 84.6562 12.979H86.0717L86.1311 14.3785H86.1761C86.3476 13.8976 86.6182 13.5103 86.9887 13.2199C87.3507 12.9585 87.7418 12.8281 88.1633 12.8281C88.3134 12.8281 88.4491 12.8388 88.5696 12.8579V14.4075Z'
      fill='white'
    />
    <path
      d='M95.8376 16.2895C95.8376 16.5807 95.8186 16.8261 95.7782 17.0266H90.9029C90.9219 17.7492 91.1575 18.3019 91.6102 18.683C92.0211 19.0237 92.5524 19.1944 93.2049 19.1944C93.9267 19.1944 94.5853 19.0793 95.1775 18.8484L95.4321 19.9765C94.74 20.2784 93.9229 20.4285 92.98 20.4285C91.8458 20.4285 90.9555 20.0947 90.3076 19.4277C89.6612 18.7607 89.3372 17.8651 89.3372 16.7415C89.3372 15.6386 89.6383 14.7201 90.2412 13.9875C90.8724 13.2055 91.7253 12.8145 92.7986 12.8145C93.8528 12.8145 94.6508 13.2055 95.1928 13.9875C95.6219 14.6088 95.8376 15.3771 95.8376 16.2895ZM94.288 15.868C94.2987 15.3863 94.1927 14.9701 93.9724 14.6187C93.6912 14.1667 93.259 13.9411 92.6774 13.9411C92.1461 13.9411 91.7139 14.1613 91.3839 14.6034C91.1133 14.9548 90.9524 15.3764 90.9029 15.8672H94.288V15.868Z'
      fill='white'
    />
  </Icon>
)

AppStore.defaultProps = {
  originalWidth: 108,
  originalHeight: 32,
  width: 108,
  height: 32,
}

export default AppStore
