import { FC } from 'react'
import { Icon } from 'ui/icons'
import { IconProps } from 'ui/icons/Icon'

const Check: FC<IconProps> = ({
  originalWidth,
  originalHeight,
  color,
  ...props
}) => (
  <Icon
    originalHeight={originalHeight}
    originalWidth={originalWidth}
    {...props}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1ZM3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12ZM17.7214 8.30747C17.3389 7.90906 16.7059 7.89614 16.3075 8.27861L10.75 13.6138L7.69253 10.6786C7.29412 10.2961 6.66109 10.3091 6.27861 10.7075C5.89614 11.1059 5.90906 11.7389 6.30747 12.1214L10.0575 15.7214C10.4444 16.0929 11.0556 16.0929 11.4425 15.7214L17.6925 9.72139C18.0909 9.33891 18.1039 8.70588 17.7214 8.30747Z'
      fill='#60B3F6'
    />
  </Icon>
)

Check.defaultProps = {
  originalWidth: 24,
  originalHeight: 24,
  width: 24,
  height: 24,
}

export default Check
