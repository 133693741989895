import { FC } from 'react'
import { Icon } from 'ui/icons'
import { IconProps } from 'ui/icons/Icon'

const AirbaMobile: FC<IconProps> = ({
  originalWidth,
  originalHeight,
  color,
  ...props
}) => (
  <Icon
    originalHeight={originalHeight}
    originalWidth={originalWidth}
    {...props}
  >
    <g clipPath='url(#clip0_707:18364)'>
      <path
        d='M0 12.5C0 5.87258 5.37258 0.5 12 0.5H28C34.6274 0.5 40 5.87258 40 12.5V28.5C40 35.1274 34.6274 40.5 28 40.5H12C5.37258 40.5 0 35.1274 0 28.5V12.5Z'
        fill='white'
      />
      <path
        d='M39.97 33.5401C39.8911 33.8909 39.8033 34.233 39.6893 34.5838C38.8122 36.987 36.9177 38.8815 34.5144 39.7585C32.3128 40.4602 30.2604 40.4602 26.173 40.4602H13.8233C9.73595 40.4602 7.6835 40.4602 5.48195 39.7585C3.07866 38.8815 1.18411 36.987 0.306995 34.5838C0.184199 34.1979 0.0877112 33.812 0 33.426V33.2594C5.92928 30.2949 12.6304 28.6284 19.7175 28.6284C27.0238 28.6284 33.9092 30.4001 39.97 33.5401Z'
        fill='url(#paint0_linear_707:18364)'
      />
      <path
        d='M25.6468 3.68418C24.3575 3.59647 23.0593 3.52631 21.7524 3.5L19.7175 3.50877L17.7002 3.5C16.3845 3.52631 15.0776 3.59648 13.7883 3.69296L7.3064 27.874C9.3413 27.4267 11.4201 27.0846 13.5339 26.8566L14.5513 23.0852C16.2529 22.9361 17.9721 22.8659 19.7175 22.8659C21.4542 22.8659 23.1821 22.9361 24.8837 23.0852L25.8924 26.8566C28.0062 27.0846 30.085 27.4267 32.1287 27.874L25.6468 3.68418ZM19.7175 17.1912C18.5071 17.1912 17.2967 17.2263 16.0951 17.2877L18.2615 9.23608H21.1735L23.3312 17.2877C22.1384 17.2263 20.9279 17.1912 19.7175 17.1912Z'
        fill='url(#paint1_linear_707:18364)'
      />
    </g>
    <defs>
      <linearGradient
        id='paint0_linear_707:18364'
        x1='0.000375017'
        y1='34.5471'
        x2='39.9716'
        y2='34.5471'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#FD7842' />
        <stop offset='0.3438' stopColor='#B346B0' />
        <stop offset='0.6823' stopColor='#605CD7' />
        <stop offset='1' stopColor='#319CF3' />
      </linearGradient>
      <linearGradient
        id='paint1_linear_707:18364'
        x1='7.30663'
        y1='15.6927'
        x2='32.1296'
        y2='15.6927'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#FD7842' />
        <stop offset='0.3438' stopColor='#B346B0' />
        <stop offset='0.6823' stopColor='#605CD7' />
        <stop offset='1' stopColor='#319CF3' />
      </linearGradient>
      <clipPath id='clip0_707:18364'>
        <path
          d='M0 12.5C0 5.87258 5.37258 0.5 12 0.5H28C34.6274 0.5 40 5.87258 40 12.5V28.5C40 35.1274 34.6274 40.5 28 40.5H12C5.37258 40.5 0 35.1274 0 28.5V12.5Z'
          fill='white'
        />
      </clipPath>
    </defs>
  </Icon>
)

AirbaMobile.defaultProps = {
  originalWidth: 40,
  originalHeight: 41,
  width: 40,
  height: 41,
}

export default AirbaMobile
