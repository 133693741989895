import { styled, theme } from 'ui/theme'
import { typography, space, color, layout } from 'styled-system'

import { TextProps } from 'ui/typography/Text/types'

const Text = styled.span<TextProps>`
  ${typography};
  ${space};
  ${color};
  ${layout};
`

Text.defaultProps = {
  theme,
}

export default Text
