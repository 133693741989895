import { FC } from 'react'
import { useTheme } from 'emotion-theming'

import { Text } from 'ui'

import { TextProps } from 'ui/typography/Text/types'
import { Theme } from 'ui/theme'

interface CaptionProps extends TextProps {
  level?: 1
  weight?: 'main'
}

const Main: FC<CaptionProps> = ({ color, children, ...props }) => {
  const theme = useTheme<Theme>()

  return (
    <Text
      {...props}
      fontSize={theme.fontSizes.title.l}
      lineHeight={theme.lineHeights.xxl[2]}
      fontWeight={theme.fontWeights.black}
    >
      {children}
    </Text>
  )
}

export default Main
