import { css, SerializedStyles } from '@emotion/core'
import { FormFieldWrapProps } from 'ui/inputs/FormField/types'

export const sizes = ({ size }: FormFieldWrapProps): SerializedStyles => {
  const defaultSize = css`
    .__form-field-input {
      min-height: 36px;
    }
  `

  const large = css`
    .__form-field-input {
      min-height: 48px;
    }
  `

  const sizeNames = {
    default: defaultSize,
    large,
  }

  return sizeNames[size]
}
