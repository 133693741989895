import { FC } from 'react'
import { Icon } from 'ui/icons'
import { IconProps } from 'ui/icons/Icon'

const BO: FC<IconProps> = ({
  originalWidth,
  originalHeight,
  color,
  ...props
}) => (
  <Icon
    originalHeight={originalHeight}
    originalWidth={originalWidth}
    {...props}
  >
    <g clipPath='url(#clip0_707:18285)'>
      <path
        d='M28.4678 15.9608C28.4608 17.7359 29.1541 19.4387 30.385 20.687C31.616 21.9425 33.2856 22.6497 35.0259 22.6497C36.745 22.6569 38.4004 21.9642 39.6243 20.7303L41.1312 19.1934L44.1237 22.1806L46.6563 19.5397L43.6921 16.5741L45.8569 14.3662L43.2889 11.7542L41.1029 13.991L34.2265 7.12897C33.8586 6.75377 33.6605 6.24146 33.6605 5.71473C33.6605 5.17356 33.8657 4.66847 34.2406 4.28605C35.0117 3.49955 36.271 3.49955 37.0421 4.28605C37.0704 4.31491 37.1058 4.35098 37.1341 4.38706L39.8648 1.95542C38.7966 0.714339 37.2614 0 35.6414 0C32.5427 0 30.0384 2.56152 30.0313 5.72194C30.0242 7.2805 30.6468 8.76691 31.7504 9.84924L30.3779 11.2491C29.1541 12.4829 28.4608 14.1858 28.4678 15.9608ZM32.946 13.8467L34.3467 12.4252C35.5706 13.6518 37.0775 15.1527 38.5348 16.6102L37.0633 18.1111C36.5186 18.6594 35.7828 18.9625 35.0188 18.9553C34.2406 18.9553 33.4907 18.645 32.946 18.0822C32.3942 17.5194 32.0829 16.7618 32.09 15.968C32.09 15.1599 32.3942 14.4095 32.946 13.8467Z'
        fill='black'
      />
      <path
        d='M20.7919 19.1357C20.7919 14.5322 18.9596 10.0225 15.7549 6.76105C12.2247 3.16049 7.39992 1.25558 1.81107 1.25558H0V56.0001H5.92135C8.14982 56.0001 11.6305 55.0981 14.1278 52.551C16.1157 50.5235 17.1627 47.7527 17.1627 44.5273C17.1627 40.9051 16.0662 37.9467 13.9014 35.7388C13.1303 34.9595 12.253 34.2956 11.298 33.7617C13.0878 32.8886 14.722 31.7125 16.1298 30.2838C19.1365 27.2172 20.7919 23.263 20.7919 19.1357ZM3.74241 31.8351V35.5367C5.9638 35.5367 9.28881 36.2799 11.3333 38.358C12.7978 39.8444 13.5335 41.9225 13.5335 44.5345C13.5335 52.0892 5.9921 52.3057 5.91428 52.3057H3.61507V5.04375C7.44237 5.39731 10.7249 6.8765 13.1798 9.3803C15.7054 11.9563 17.1486 15.5135 17.1486 19.1357C17.1627 26.0194 11.015 31.8351 3.74241 31.8351Z'
        fill='black'
      />
      <path
        d='M46.6138 29.6632C43.8123 26.7914 39.9991 25.1751 36.0233 25.1895C27.7673 25.1895 21.0466 32.0443 21.0466 40.4649C21.0466 44.5417 22.603 48.3804 25.4328 51.2666C28.2343 54.1384 32.0474 55.7547 36.0233 55.7402C40.0274 55.7402 43.784 54.1528 46.6138 51.2666C49.4436 48.3804 51 44.5417 51 40.4649C51 36.3881 49.4365 32.5494 46.6138 29.6632ZM36.0233 52.0387C33.0095 52.0459 30.1231 50.8264 28.0008 48.6473C25.8643 46.4827 24.6687 43.5315 24.6758 40.4577C24.6758 37.3694 25.8572 34.4616 28.0008 32.2752C30.1231 30.0961 33.0166 28.8767 36.0233 28.8839C39.0582 28.8839 41.9092 30.0889 44.0457 32.2752C46.1822 34.4399 47.3778 37.3911 47.3707 40.4577C47.3707 46.8507 42.2771 52.0387 36.0233 52.0387Z'
        fill='black'
      />
    </g>
    <defs>
      <clipPath id='clip0_707:18285'>
        <rect width='51' height='56' fill='white' />
      </clipPath>
    </defs>
  </Icon>
)

BO.defaultProps = {
  originalWidth: 51,
  originalHeight: 56,
  width: 51,
  height: 56,
}

export default BO
