import { Theme } from 'ui/theme'

export const getTooltipStyles = (theme: Theme) => `
  .rc-tooltip {
    opacity: 1;

    .rc-tooltip-inner {
      min-height: auto;
      padding: 4px;
      border-radius: ${theme.radii.xxs};
      line-height: ${theme.lineHeights.m};
      font-size: ${theme.fontSizes.body.m};
      font-weight: ${theme.fontWeights.semibold};
      background-color: ${theme.colors.main.background.black};
    }
  }

  .rc-tooltip-placement-right .rc-tooltip-arrow,
  .rc-tooltip-placement-rightTop .rc-tooltip-arrow,
  .rc-tooltip-placement-rightBottom .rc-tooltip-arrow {
    border-right-color: ${theme.colors.main.background.black};
    left: 2px;
    margin-top: -3px;
    border-width: 4px 7px 4px 0;
  }

  .rc-tooltip-placement-top .rc-tooltip-arrow,
  .rc-tooltip-placement-topLeft .rc-tooltip-arrow,
  .rc-tooltip-placement-topRight .rc-tooltip-arrow {
    border-top-color: ${theme.colors.main.background.black};
    bottom: 2px;
    margin-left: -4px;
    border-width: 7px 4px 0;
  }

  .rc-tooltip-placement-left .rc-tooltip-arrow,
  .rc-tooltip-placement-leftTop .rc-tooltip-arrow,
  .rc-tooltip-placement-leftBottom .rc-tooltip-arrow {
    border-left-color: ${theme.colors.main.background.black};
    right: 2px;
    margin-top: -3px;
    border-width: 4px 0 4px 7px;
  }

  .rc-tooltip-placement-bottom .rc-tooltip-arrow,
  .rc-tooltip-placement-bottomLeft .rc-tooltip-arrow,
  .rc-tooltip-placement-bottomRight .rc-tooltip-arrow {
    border-bottom-color: ${theme.colors.main.background.black};
    top: 2px;
    margin-left: -4px;
    border-width: 0 4px 7px;
  }
`
