import React, { ReactNode } from 'react'

export type SizesType = 'default' | 'large'

type InputDefaultProps = React.InputHTMLAttributes<HTMLInputElement>

type InputProps = {
  size?: SizesType
} & Omit<InputDefaultProps, 'size'>

export interface FormFieldProps extends InputProps {
  label?: string
  subText?: string
  error?: boolean
  tooltip?: {
    overlay: ReactNode
    visible: boolean
    placement?: string
  }
  onClear?: VoidFunction
  maskType?: 'PHONE' | 'CODE' | 'BIN'
  allowClear?: boolean
  isCenter?: boolean
}

export interface FormFieldWrapProps {
  size: SizesType
  error: boolean
  height?: string
  allowClear?: boolean
  value: string | ReadonlyArray<string> | number | undefined
  isCenter?: boolean
}

export enum FormFieldMaskType {
  PHONE = '+7 (999) 999-99-99',
  CODE = '9 9 9 9',
  BIN = '999999999999'
}
