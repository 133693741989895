import {
  Box,
  Button,
  FormField,
  Typography,
  Checkbox,
  Anchor,
  styled,
  theme,
} from 'ui'
import {
  Section,
  IpadSection,
  MobileSection,
  ContentBox,
  MainText,
} from '../components'

const Form = styled.form`
  padding: 16px;
  width: 417px;
  z-index: 99;
  background: #ffffff;
  box-shadow: 0 31px 41px rgba(16, 20, 45, 0.11),
    0 14.604px 12.4842px rgba(16, 20, 45, 0.0625218),
    0 2.72809px 0.0569254px rgba(16, 20, 45, 0.0394782);
  border-radius: 14px;
  @media only screen and (min-device-width: 280px) and (max-device-width: 1023px) {
    width: 100%;
    box-sizing: border-box;
    margin-top: 24px;
    position: relative;
    margin-bottom: 24px;
  }
`

const SendRequestButton = styled(Button)`
  margin-top: 16px;
`

const { Body, Caption } = Typography

function match(event, reg) {
  if (event.target.value.search(reg) !== -1) {
    event.target.value = event.target.value.replace(reg, '')
  }
}

export const matchNumber = event => {
  const reg = /\D*/g
  match(event, reg)
}

const RegisterForm = ({
  name,
  setName,
  phoneError,
  phone,
  setPhone,
  isAgree,
  setIsAgree,
  onSubmit,
  bin,
  email,
  company,
  setBin,
  setEmail,
  isLoading,
  handlePhone,
  setCompany,
  isReadonlyCompany,
}) => (
  <Form onSubmit={onSubmit}>
    <Body weight='semibold' as='p' pb='16px'>
      Форма заявки на подключение
    </Body>
    <Box pb='16px'>
      <FormField
        placeholder='Имя'
        label='Ваше имя'
        size='large'
        type='text'
        value={name}
        onChange={e => setName(e.target.value)}
        allowClear={false}
      />
    </Box>
    <Box pb='16px'>
      <FormField
        placeholder='+7 (_ _ _) _ _ _-_ _-_ _'
        label='Номер телефона'
        maskType='PHONE'
        size='large'
        type='text'
        error={phoneError}
        onBlur={handlePhone}
        value={phone}
        onChange={e => setPhone(e.target.value)}
        allowClear={false}
      />
    </Box>
    <Box pb='16px'>
      <FormField
        placeholder='example@triger.com'
        label='Email'
        size='large'
        type='text'
        value={email}
        onChange={e => setEmail(e.target.value)}
        allowClear={false}
      />
    </Box>
    <Box pb='16px'>
      <FormField
        placeholder='БИН или ИИН'
        label='БИН или ИИН'
        size='large'
        type='text'
        value={bin}
        onInput={matchNumber}
        onChange={e => {
          if (e.target.value.length <= 12) {
            setBin(e.target.value)
          }
        }}
        allowClear={false}
      />
    </Box>
    <Box pb='16px'>
      <FormField
        placeholder='Название компании'
        label='Название компании'
        onChange={e => setCompany(e.target.value)}
        size='large'
        type='text'
        value={company}
        allowClear={false}
        readOnly={isReadonlyCompany}
      />
    </Box>

    <Checkbox
      checked={isAgree}
      onChange={e => setIsAgree(e.currentTarget.checked)}
    >
      <Caption>
        Я согласен на обработку{' '}
        <Anchor href='https://airba.kz/info/privacy_policy'>
          персональных данных
        </Anchor>
      </Caption>
    </Checkbox>

    <SendRequestButton
      fullWidth
      disabled={
        !isAgree ||
        !name ||
        phone.length < 11 ||
        !email ||
        !company ||
        bin.length !== 12
      }
      loading={isLoading}
    >
      Регистрация
    </SendRequestButton>
  </Form>
)

const RequestSection = ({
  name,
  setName,
  phoneError,
  isReadonlyCompany,
  phone,
  setPhone,
  isAgree,
  setIsAgree,
  onSubmit,
  handlePhone,
  isLoading,
  bin,
  email,
  company,
  setBin,
  setEmail,
  setCompany,
}) => {
  return (
    <>
      <Section background={theme.colors.main.background.black} id='register'>
        <ContentBox display='flex' justifyContent='space-between' py='48px'>
          <Box position='absolute' top={0} left={279}>
            <img
              src='https://i.ibb.co/Wymj1bK/elements-object-17.png'
              alt='element'
            />
          </Box>
          <Box position='absolute' top={240} left='50%'>
            <img
              src='https://i.ibb.co/VJ7sVGs/elements-object-16.png'
              alt='element'
            />
          </Box>
          <Box position='absolute' left={-30} bottom={91}>
            <img src='https://i.ibb.co/qmQwXQH/Mask-Group.png' alt='element' />
          </Box>
          <Box position='absolute' bottom={-5} left={480}>
            <img
              src='https://i.ibb.co/Ny6QDyH/elements-object-8.png'
              alt='element'
            />
          </Box>
          <Box position='absolute' right={4} bottom={172}>
            <img
              src='https://i.ibb.co/6vgn9KB/elements-object-9.png'
              alt='element'
            />
          </Box>
          <MainText mt='132px' width='55%'>
            Регистрируйтесь на онлайн маркете
          </MainText>
          <RegisterForm
            name={name}
            setName={setName}
            phoneError={phoneError}
            phone={phone}
            setPhone={setPhone}
            isAgree={isAgree}
            setIsAgree={setIsAgree}
            onSubmit={onSubmit}
            isLoading={isLoading}
            handlePhone={handlePhone}
            bin={bin}
            email={email}
            company={company}
            setBin={setBin}
            setEmail={setEmail}
            setCompany={setCompany}
            isReadonlyCompany={isReadonlyCompany}
          />
        </ContentBox>
      </Section>

      <IpadSection
        background={theme.colors.main.background.black}
        id='ipad-register'
      >
        <ContentBox display='flex' justifyContent='space-between' py='48px'>
          <Box position='absolute' top={0} left={279}>
            <img
              src='https://i.ibb.co/Wymj1bK/elements-object-17.png'
              alt='element'
            />
          </Box>
          <Box position='absolute' top={150} left='50%'>
            <img
              src='https://i.ibb.co/VJ7sVGs/elements-object-16.png'
              alt='element'
            />
          </Box>
          <Box position='absolute' left={-30} bottom={91}>
            <img src='https://i.ibb.co/qmQwXQH/Mask-Group.png' alt='element' />
          </Box>
          <Box position='absolute' bottom={-5} left={480}>
            <img
              src='https://i.ibb.co/Ny6QDyH/elements-object-8.png'
              alt='element'
            />
          </Box>
          <Box position='absolute' right={-3} bottom={172}>
            <img
              src='https://i.ibb.co/6vgn9KB/elements-object-9.png'
              alt='element'
            />
          </Box>
          <MainText mt='132px' width='55%'>
            Регистрируйтесь на онлайн маркете
          </MainText>
          <RegisterForm
            name={name}
            setName={setName}
            phoneError={phoneError}
            phone={phone}
            setPhone={setPhone}
            isAgree={isAgree}
            setIsAgree={setIsAgree}
            onSubmit={onSubmit}
            isLoading={isLoading}
            handlePhone={handlePhone}
            bin={bin}
            email={email}
            company={company}
            setBin={setBin}
            setEmail={setEmail}
            setCompany={setCompany}
            isReadonlyCompany={isReadonlyCompany}
          />
        </ContentBox>
      </IpadSection>

      <MobileSection
        background={theme.colors.main.background.black}
        id='mobile-register'
      >
        <ContentBox>
          <Box position='absolute' top={0} right={79}>
            <img
              src='https://i.ibb.co/Cn1hFP5/elements-object-18.png'
              alt='element'
            />
          </Box>
          <Box position='absolute' top={74} right={9}>
            <img
              src='https://i.ibb.co/hLNV7d9/elements-object-11.png'
              alt='element'
            />
          </Box>
          <Box position='absolute' top={220} right={-1}>
            <img
              src='https://i.ibb.co/hCpQQYx/elements-object-12.png'
              alt='element'
            />
          </Box>
          <Box position='absolute' bottom={128} right={-1}>
            <img
              src='https://i.ibb.co/dphf7Hc/elements-object-13.png'
              alt='element'
            />
          </Box>
          <Box position='absolute' bottom={-5} left={77}>
            <img
              src='https://i.ibb.co/Xzc957K/elements-object-14.png'
              alt='element'
            />
          </Box>
          <Box position='absolute' bottom={275} left={-1}>
            <img
              src='https://i.ibb.co/P6GW3bb/elements-object-15.png'
              alt='element'
            />
          </Box>
          <MainText>Регистрируйтесь на онлайн маркете</MainText>
          <RegisterForm
            name={name}
            setName={setName}
            phoneError={phoneError}
            phone={phone}
            setPhone={setPhone}
            isAgree={isAgree}
            setIsAgree={setIsAgree}
            onSubmit={onSubmit}
            isLoading={isLoading}
            handlePhone={handlePhone}
            bin={bin}
            email={email}
            company={company}
            setBin={setBin}
            setEmail={setEmail}
            setCompany={setCompany}
            isReadonlyCompany={isReadonlyCompany}
          />
        </ContentBox>
      </MobileSection>
    </>
  )
}

export default RequestSection
