import { Box, Typography, Carousel, theme } from 'ui'
import {
  Section,
  IpadSection,
  MobileSection,
  ContentBox,
  ScrollBox,
  MainText,
} from '../components'

const { Body } = Typography

const categories = [
  {
    img: 'https://i.ibb.co/Yp9QWdt/Rectangle-519-4.png',
    title: <Body weight='semibold'>Автотовары</Body>,
    commission: 'https://i.ibb.co/kJCkFB0/count.png',
  },
  // {
  //   img: 'https://i.ibb.co/Xkyhnrm/Rectangle-519-5.png',
  //   title: (
  //     <Body weight='semibold'>
  //       Бытовая <br /> техника
  //     </Body>
  //   ),
  //   commission: 'https://i.ibb.co/9g6RZ5z/count-1.png',
  // },
  {
    img: 'https://i.ibb.co/sR8W5Fk/Rectangle-519-8.png',
    title: (
      <Body weight='semibold'>
        Детские <br /> товары
      </Body>
    ),
    commission: 'https://i.ibb.co/3NfXkcw/count-2.png',
  },
  {
    img: 'https://i.ibb.co/jHCxdrP/Rectangle-519-9.png',
    title: (
      <Body weight='semibold'>
        Товары для <br /> дома и дачи
      </Body>
    ),
    commission: 'https://i.ibb.co/kJCkFB0/count.png',
  },
  {
    img: 'https://i.ibb.co/C0JdQm8/Rectangle-519-10.png',
    title: (
      <Body weight='semibold'>
        Красота и <br /> здоровье
      </Body>
    ),
    commission: 'https://i.ibb.co/jymTS8r/count-3.png',
  },
  // {
  //   img: 'https://i.ibb.co/s3Z27Sp/Rectangle-519-3.png',
  //   title: <Body weight='semibold'>Компьютеры</Body>,
  //   commission: 'https://i.ibb.co/9g6RZ5z/count-1.png',
  // },
  {
    img: 'https://i.ibb.co/74RYhqh/Rectangle-519-12.png',
    title: (
      <Body weight='semibold'>
        Ремонт <br />и строительство
      </Body>
    ),
    commission: 'https://i.ibb.co/gDTSfn0/count-5.png',
  },
  {
    img: 'https://i.ibb.co/hdTnQPS/Rectangle-519-7.png',
    title: (
      <Body weight='semibold'>
        Спорт <br />и туризм
      </Body>
    ),
    commission: 'https://i.ibb.co/3NfXkcw/count-2.png',
  },
  // {
  //   img: 'https://i.ibb.co/DGzFHk2/Rectangle-519-6.png',
  //   title: <Body weight='semibold'>Электроника</Body>,
  //   commission: 'https://i.ibb.co/9g6RZ5z/count-1.png',
  // },
  // {
  //   img: 'https://i.ibb.co/7YSKH3x/Rectangle-519-1.png',
  //   title: (
  //     <Body weight='semibold'>
  //       Телефоны <br />и гаджеты
  //     </Body>
  //   ),
  //   commission: 'https://i.ibb.co/9g6RZ5z/count-1.png',
  // },
  {
    img: 'https://i.ibb.co/PG8tsh9/Rectangle-519-11.png',
    title: (
      <Body weight='semibold'>
        Украшения <br />и аксессуары
      </Body>
    ),
    commission: 'https://i.ibb.co/Mfwj8kx/count-4.png',
  },
]

const CategoriesSection = () => {
  return (
    <>
      <Section
        height='480px'
        background={theme.colors.main.background.white}
        id='category'
      >
        <ContentBox py='48px'>
          <MainText mb='32px'>Что можно продавать</MainText>
          <Carousel categories={categories} />
        </ContentBox>
      </Section>

      <IpadSection
        height='434px'
        background={theme.colors.main.background.white}
        id='ipad-category'
      >
        <ContentBox>
          <MainText mt='48px' mb='32px'>
            Что можно продавать
          </MainText>
          <Carousel categories={categories} />
        </ContentBox>
      </IpadSection>

      <MobileSection
        height='407px'
        background={theme.colors.main.background.white}
        id='mobile-category'
      >
        <MainText p='24px' pb='11px'>
          Что можно продавать
        </MainText>
        <ScrollBox px='16px'>
          {categories.map((category, i) => (
            <Box mr='24px' key={i}>
              <Box display='flex' justifyContent='center' pt='7px'>
                <img src={category.img} alt='category' />
              </Box>
              <Box
                mt='10px'
                height='47px'
                display='flex'
                justifyContent='center'
                textAlign='center'
              >
                {category.title}
              </Box>
              <Box display='flex' justifyContent='center' pt='20px'>
                <img src={category.commission} alt='commission' />
              </Box>
            </Box>
          ))}
        </ScrollBox>
      </MobileSection>
    </>
  )
}

export default CategoriesSection
