import React, { FC, useState } from 'react'
import { CSSTransition } from 'react-transition-group'
import { useTheme } from 'emotion-theming'
import InputMask from 'react-input-mask'

import { FormFieldWrap } from 'ui/inputs/FormField/styles'
import { Condition, Tooltip, Typography } from 'ui'
import { DeniedIcon, LockIcon } from 'ui/icons'

import {
  PasswordVisibilityIcon,
  ClearValueIcon,
} from 'ui/inputs/FormField/components'

import { FormFieldProps, FormFieldMaskType } from 'ui/inputs/FormField/types'
import { Theme } from 'ui/theme'

const { Caption } = Typography

const FormField: FC<FormFieldProps> = ({
  label,
  subText,
  size,
  maskType,
  type,
  error,
  onClear,
  tooltip,
  allowClear,
  disabled,
  isCenter,
  ...props
}) => {
  const theme = useTheme<Theme>()
  const [passwordType, setPasswordType] = useState('password')

  const onEyeClick = () => {
    setPasswordType(passwordType === 'password' ? 'text' : 'password')
  }

  const FormFieldContainer = (
    <div className='__form-field-container'>
      <CSSTransition
        in={error}
        timeout={100}
        unmountOnExit
        classNames='__form-field-error'
      >
        <div className='__form-field-error-icon'>
          <DeniedIcon color={theme.colors.main.status.error} />
        </div>
      </CSSTransition>

      {maskType ? (
        <InputMask
          {...(props as any)}
          disabled={disabled}
          className='__form-field-input'
          isCenter
          mask={FormFieldMaskType[maskType]}
          type={type === 'password' ? passwordType : type}
        />
      ) : (
        <input
          {...props}
          disabled={disabled}
          className='__form-field-input'
          type={type === 'password' ? passwordType : type}
        />
      )}

      <Condition match={type === 'password'}>
        <PasswordVisibilityIcon onEyeClick={onEyeClick} type={passwordType} />
      </Condition>

      <Condition match={props.readOnly === true}>
        <LockIcon color='icon.black' className='__form-field-lock' />
      </Condition>

      <Condition
        match={
          type !== 'password' && String(props.value).length !== 0 && allowClear
        }
      >
        <ClearValueIcon onClear={onClear} />
      </Condition>
    </div>
  )

  return (
    <FormFieldWrap
      size={size}
      error={error}
      value={props.value}
      allowClear={allowClear}
    >
      <label>
        <Condition match={Boolean(label)}>
          <Caption
            as='p'
            mb='4px'
            weight='semibold'
            color={
              disabled
                ? theme.greyColorPalette[20]
                : theme.colors.projects.primaryMarket
            }
          >
            {label}
          </Caption>
        </Condition>

        {!tooltip ? (
          FormFieldContainer
        ) : (
          <Tooltip
            placement={tooltip.placement || 'right'}
            visible={tooltip.visible}
            overlay={tooltip.overlay}
          >
            {FormFieldContainer}
          </Tooltip>
        )}
      </label>

      <Condition match={Boolean(subText)}>
        <Caption
          as='p'
          mt='8px'
          weight='semibold'
          color={
            disabled
              ? theme.greyColorPalette[20]
              : theme.colors.text.secondaryBlack
          }
        >
          {subText}
        </Caption>
      </Condition>
    </FormFieldWrap>
  )
}

FormField.defaultProps = {
  size: 'default',
  allowClear: true,
}

export default FormField
