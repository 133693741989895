import { styled } from 'ui/theme'

import type { FC, ReactNode, ChangeEventHandler } from 'react'

const Label = styled.label`
  display: flex;
  align-items: center;
`

const Checkbox = styled.div`
  min-width: 16px;
  min-height: 16px;
  border-radius: 4px;
  margin-right: 8px;
  background-repeat: no-repeat;
  background-position: center center;
  transition: 0.2s;
  border: 2px solid ${props => props.theme.colors.icon.secondaryBlack};
`

const Input = styled.input`
  position: absolute;
  appearance: none;

  &:checked + .checkbox {
    fill: red;
    color: red;
    border-color: ${props => props.theme.colors.projects.primaryMarket};
    background-color: ${props => props.theme.colors.projects.primaryMarket};
    background-image: url("data:image/svg+xml,%3Csvg width='10' height='8' viewBox='0 0 10 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M9.64018 0.231804C10.0645 0.585368 10.1218 1.21593 9.76822 1.64021L4.76822 7.64021C4.58836 7.85605 4.32599 7.98627 4.04531 7.999C3.76464 8.01173 3.49156 7.9058 3.29289 7.70713L0.792893 5.20713C0.402369 4.81661 0.402369 4.18344 0.792893 3.79292C1.18342 3.40239 1.81658 3.40239 2.20711 3.79292L3.9328 5.51861L8.23178 0.359841C8.58534 -0.0644363 9.21591 -0.12176 9.64018 0.231804Z' fill='%23FCFCFD'/%3E%3C/svg%3E%0A");
  }
`

interface Props {
  checked: boolean
  onChange: ChangeEventHandler<HTMLInputElement>
  children?: ReactNode
}

const CheckboxComp: FC<Props> = ({ checked, onChange, children }) => (
  <Label>
    <Input type='checkbox' checked={checked} onChange={onChange} />
    <Checkbox className='checkbox' />
    {children}
  </Label>
)

export { CheckboxComp as Checkbox }
