import { Box, Typography, styled, theme } from 'ui'
import {
  AppleIcon,
  BOIcon,
  LGIcon,
  MIIcon,
  SamsungIcon,
  SonyIcon,
} from 'ui/icons'
import { Section, IpadSection, MobileSection, ContentBox } from '../components'

const WorkingWithBox = styled(Box)`
  background: #fcfcff;
  border-radius: 24px;
  width: 100%;
  height: 208px;
  padding: 34px 50px 47px 48px;
  @media only screen and (min-device-width: 280px) and (max-device-width: 1023px) {
    width: 100%;
    box-sizing: border-box;
    height: 231px;
    padding: 24px;
  }
  @media only screen and (min-device-width: 1024px) and (max-device-width: 1231px) {
    width: 100%;
    box-sizing: border-box;
    height: 158px;
    padding: 24px;
  }
`

const { Body, Subtitle } = Typography

const PartnersSection = () => {
  return (
    <>
      <Section background={theme.colors.main.background.black} id='partners'>
        <ContentBox py='48px'>
          <WorkingWithBox>
            <Box>
              <Subtitle>Через нас уже продают</Subtitle>
            </Box>
            <Box
              display='flex'
              alignItems='center'
              justifyContent='space-between'
              pt='46px'
            >
              <SonyIcon />
              <LGIcon />
              <AppleIcon />
              <MIIcon />
              <SamsungIcon />
              <BOIcon />
            </Box>
          </WorkingWithBox>
        </ContentBox>
      </Section>

      <IpadSection
        height='254px'
        background={theme.colors.main.background.black}
        id='ipad-partners'
      >
        <ContentBox py='48px'>
          <WorkingWithBox>
            <Box>
              <Subtitle>Через нас уже продают</Subtitle>
            </Box>
            <Box
              display='flex'
              alignItems='center'
              justifyContent='space-between'
              pt='27px'
            >
              <SonyIcon />
              <LGIcon />
              <AppleIcon />
              <MIIcon />
              <SamsungIcon />
              <BOIcon />
            </Box>
          </WorkingWithBox>
        </ContentBox>
      </IpadSection>

      <MobileSection
        height='279px'
        background={theme.colors.main.background.black}
        id='mobile-partners'
      >
        <ContentBox py='24px'>
          <WorkingWithBox>
            <Body>Через нас уже продают</Body>
            <Box
              display='flex'
              alignItems='center'
              justifyContent='space-between'
              pt='33px'
            >
              <LGIcon width={57} height={29} />
              <SonyIcon width={73} height={13} />
              <BOIcon width={26} height={29} />
            </Box>
            <Box
              display='flex'
              alignItems='center'
              justifyContent='space-between'
              mt='60px'
            >
              <AppleIcon width={64} height={21} />
              <MIIcon width={34} height={34} />
              <SamsungIcon width={77} height={12} />
            </Box>
          </WorkingWithBox>
        </ContentBox>
      </MobileSection>
    </>
  )
}

export default PartnersSection
