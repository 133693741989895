import { FC, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { Wrapper } from 'ui/layout/PageLayout'
import { Box, Button, Typography, theme } from 'ui'

import { HeaderBlock, FooterBlock } from '../../components'
import {
  Section,
  IpadSection,
  MobileSection,
  ContentBox,
  MainText,
} from '../../components/components'

interface Props {}

const { Body, Subtitle } = Typography

const SuccessRegistration: FC<Props> = () => {
  const [mobileMenu, setMobileMenu] = useState<boolean>(false)
  const history = useHistory()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <Wrapper>
      <HeaderBlock
        mobileMenu={mobileMenu}
        setMobileMenu={setMobileMenu}
        successPage
      />

      <Section background={theme.colors.main.background.white}>
        <ContentBox>
          <Box position='absolute' top='-14%' right='-29%'>
            <img
              src='https://i.ibb.co/xjkVSKg/Rectangle-475.png'
              alt='rectangle'
            />
          </Box>
          <Box position='absolute' top='141px' right='45px'>
            <img src='https://i.ibb.co/PtD0CzG/0040-1-1.png' alt='airba' />
          </Box>
          <MainText pt='151px'>
            Заявка успешно <br /> отправлена
          </MainText>
          <Box position='relative' py='44px'>
            <Subtitle>
              Спасибо, мы отправили письмо
              <br />
              C вами свяжутся в ближайшее время
              <br />
              Номер контакт центра: 6980
            </Subtitle>
          </Box>
          <Button onClick={() => history.push('/')}>Вернуться</Button>
        </ContentBox>
      </Section>
      <IpadSection background={theme.colors.main.background.white}>
        <ContentBox>
          <Box position='absolute' top='-14%' right='-60%'>
            <img
              src='https://i.ibb.co/xjkVSKg/Rectangle-475.png'
              alt='rectangle'
            />
          </Box>
          <Box position='absolute' top='141px' right='1px'>
            <img src='https://i.ibb.co/PtD0CzG/0040-1-1.png' alt='airba' />
          </Box>
          <MainText pt='151px'>
            Заявка успешно <br /> отправлена
          </MainText>
          <Box position='relative' py='44px'>
            <Subtitle>
              Спасибо, мы отправили письмо
              <br />
              C вами свяжутся в ближайшее время
              <br />
              Номер контакт центра: 6980
            </Subtitle>
          </Box>
          <Button onClick={() => history.push('/')}>Вернуться</Button>
        </ContentBox>
      </IpadSection>
      <MobileSection background={theme.colors.main.background.white}>
        <ContentBox>
          <Box position='absolute' right={0} bottom={-4}>
            <img
              src='https://i.ibb.co/nsMcTzP/Rectangle-505-2.png'
              alt='rectangle'
            />
          </Box>
          <Box position='absolute' right={24} bottom={16}>
            <img src='https://i.ibb.co/3Wf78rW/0040-1-2.png' alt='airba' />
          </Box>
          <Box position='absolute' right={14} bottom={16}>
            <img
              src='https://i.ibb.co/bQ55XZC/elements-object-5.png'
              alt='elements'
            />
          </Box>
          <MainText>
            Заявка успешно <br /> отправлена
          </MainText>
          <Box py='24px'>
            <Body weight='semibold'>
              Спасибо, мы отправили письмо
              <br />
              C вами свяжутся в ближайшее время
              <br />
              Номер контакт центра:
              <br />
              6980
            </Body>
          </Box>
          <Button fullWidth onClick={() => history.push('/')}>
            Вернуться
          </Button>
        </ContentBox>
      </MobileSection>

      <FooterBlock />
    </Wrapper>
  )
}

export default SuccessRegistration
