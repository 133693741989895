import { Global } from '@emotion/core'

import { getToastStyles } from 'ui/toast/styles'
import { CloseOutlinedIcon } from 'ui/icons'

import { Text } from 'ui/typography'

import Notification from 'rc-notification'

import type { FC, ReactNode } from 'react'
import type { ToastContainerProps, ToastOptions } from 'ui/toast/types'
import type { NotificationInstance } from 'rc-notification/lib/Notification'

let notificationInstance: NotificationInstance = null
Notification.newInstance({}, n => {
  notificationInstance = n
})

const ToastContainer: FC<ToastContainerProps> = ({
  onCancelAction,
  children,
}) => (
  <>
    <Global styles={getToastStyles} />
    <div className='rc-notification-content'>
      <Text color='#FCFCFF' fontSize='15px' lineHeight='24px'>
        {children}
      </Text>

      {onCancelAction && (
        <div
          className='rc-notification-content-cancel-button'
          onClick={onCancelAction}
        >
          <Text color='#60B3F6' fontSize='15px' lineHeight='24px'>
            Отменить
          </Text>
        </div>
      )}
    </div>
  </>
)

const toast = (text: ReactNode, options?: ToastOptions) => {
  notificationInstance.notice({
    key: options?.key,
    duration: options?.onCancelAction ? 7 : options?.loading ? 0 : 3,
    closable: Boolean(options?.onCancelAction),
    content: (
      <ToastContainer onCancelAction={options?.onCancelAction}>
        {text}
      </ToastContainer>
    ),
    closeIcon: <CloseOutlinedIcon color='#888A96' />,
  })

  if (options?.removeNotice) {
    notificationInstance.removeNotice(options?.key)
  }
}

export default toast
