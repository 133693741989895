import { FC } from 'react'
import { Icon } from 'ui/icons'
import { IconProps } from 'ui/icons/Icon'

const ActiveCircle: FC<IconProps> = ({
  originalWidth,
  originalHeight,
  color,
  ...props
}) => (
  <Icon
    originalHeight={originalHeight}
    originalWidth={originalWidth}
    {...props}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2Z'
      fill='#319CF3'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M14.6402 6.2318C15.0645 6.58537 15.1218 7.21593 14.7682 7.64021L9.76822 13.6402C9.58836 13.856 9.32599 13.9863 9.04531 13.999C8.76464 14.0117 8.49156 13.9058 8.29289 13.7071L5.79289 11.2071C5.40237 10.8166 5.40237 10.1834 5.79289 9.79292C6.18342 9.40239 6.81658 9.40239 7.20711 9.79292L8.9328 11.5186L13.2318 6.35984C13.5853 5.93556 14.2159 5.87824 14.6402 6.2318Z'
      fill='#FCFCFD'
    />
  </Icon>
)

ActiveCircle.defaultProps = {
  originalWidth: 20,
  originalHeight: 20,
  width: 20,
  height: 20,
}

export default ActiveCircle
