import { FC } from 'react'
import { useTheme } from 'emotion-theming'

import { Text } from 'ui'

import { TextProps } from 'ui/typography/Text/types'
import { Theme } from 'ui/theme'

interface CaptionProps extends TextProps {
  level?: 1 | 2
  weight?: 'regular' | 'semibold' | 'bold'
}

const Caption: FC<CaptionProps> = ({
  level,
  color,
  weight,
  children,
  ...props
}) => {
  const theme = useTheme<Theme>()

  const levels = {
    1: theme.fontSizes.caption.m,
    2: theme.fontSizes.caption.s,
  }

  return (
    <Text
      {...props}
      fontSize={levels[level]}
      lineHeight={theme.lineHeights.xs}
      fontWeight={theme.fontWeights[weight]}
      color={color || theme.colors.text.black}
    >
      {children}
    </Text>
  )
}

Caption.defaultProps = {
  level: 1,
  weight: 'regular',
}

export default Caption
