import { FC } from 'react'
import { useTheme } from 'emotion-theming'
import { Global, css } from '@emotion/core'

import BaseTooltip from 'rc-tooltip'

import { getTooltipStyles } from 'ui/tooltip/styles'

import { TooltipProps } from 'rc-tooltip/lib/Tooltip'
import { Theme } from 'ui/theme'

const Tooltip: FC<TooltipProps> = ({ children, ...props }) => {
  const theme = useTheme<Theme>()

  return (
    <>
      <Global
        styles={css`
          ${getTooltipStyles(theme)}
        `}
      />
      <BaseTooltip {...props}>{children}</BaseTooltip>
    </>
  )
}

Tooltip.defaultProps = {
  animation: 'zoom',
}

export default Tooltip
