import type { FC } from 'react'
import { Icon } from 'ui/icons'
import { IconProps } from 'ui/icons/Icon'

import { Rotate, rotate } from 'ui/icons/Chevron/utlits'

const Chevron: FC<IconProps & Rotate> = ({
  originalWidth,
  originalHeight,
  color,
  up,
  right,
  down,
  left,
  ...props
}) => (
  <Icon
    originalHeight={originalHeight}
    originalWidth={originalWidth}
    {...props}
  >
    <g transform={rotate({ up, right, down, left }, props.width, props.height)}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M14.7071 8.29289C14.3166 7.90237 13.6834 7.90237 13.2929 8.29289L10 11.5858L6.70711 8.29289C6.31658 7.90237 5.68342 7.90237 5.29289 8.29289C4.90237 8.68342 4.90237 9.31658 5.29289 9.7071L9.29289 13.7071C9.48043 13.8946 9.73478 14 10 14C10.2652 14 10.5196 13.8946 10.7071 13.7071L14.7071 9.7071C15.0976 9.31658 15.0976 8.68342 14.7071 8.29289Z'
        fill={color}
      />
    </g>
  </Icon>
)

Chevron.defaultProps = {
  originalWidth: 20,
  originalHeight: 20,
  width: 20,
  height: 20,
  color: '#888A96',
}

export default Chevron
