import React from 'react'
import { useTheme } from 'emotion-theming'
import RcSelect, { Option } from 'rc-select'

import { getSelectStyles, SelectWrap } from 'ui/inputs/Select/styles'
import { ActiveCircleIcon, ChevronIcon, CloseIcon } from 'ui/icons'
import { Global, css } from '@emotion/core'
import { Typography, Condition } from 'ui'

import { SelectProps } from 'ui/inputs/Select/types'
import { Theme } from 'ui/theme'

const { Caption } = Typography

const Select: SelectProps = ({
  children,
  disabled,
  label,
  placeholder,
  subText,
  size,
  ...props
}) => {
  const theme = useTheme<Theme>()

  return (
    <SelectWrap size={size}>
      <Global
        styles={css`
          ${getSelectStyles(theme)}
        `}
      />
      <Condition match={Boolean(label)}>
        <Caption
          as='p'
          mb='4px'
          weight='semibold'
          color={
            disabled
              ? theme.greyColorPalette[20]
              : theme.colors.projects.primaryMarket
          }
        >
          {label}
        </Caption>
      </Condition>
      <RcSelect
        {...props}
        disabled={disabled}
        menuItemSelectedIcon={<ActiveCircleIcon />}
        placeholder={placeholder}
        clearIcon={<CloseIcon color={theme.colors.icon.secondaryBlack} />}
        inputIcon={<ChevronIcon color={theme.colors.icon.black} down />}
      >
        {children}
      </RcSelect>
      <Condition match={Boolean(subText)}>
        <Caption
          as='p'
          mt='8px'
          weight='semibold'
          color={
            disabled
              ? theme.greyColorPalette[20]
              : theme.colors.text.secondaryBlack
          }
        >
          {subText}
        </Caption>
      </Condition>
    </SelectWrap>
  )
}

Select.Option = Option
Select.Option.displayName = 'Select.Option'

Select.defaultProps = {
  size: 'default',
  animation: 'slide-up',
}

export default Select
