import { FC } from 'react'
import { Icon } from 'ui/icons'
import { IconProps } from 'ui/icons/Icon'

const Youtube: FC<IconProps> = ({
  originalWidth,
  originalHeight,
  color,
  ...props
}) => (
  <Icon
    originalHeight={originalHeight}
    originalWidth={originalWidth}
    {...props}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M16.5348 4.51524C17.2388 4.71202 17.7889 5.26299 17.9853 5.96809C18.2265 7.30071 18.3428 8.65298 18.3327 10.0073C18.3418 11.3565 18.2255 12.7036 17.9853 14.0311C17.7889 14.7362 17.2388 15.2872 16.5348 15.484C15.2218 15.8333 10.0074 15.8333 10.0074 15.8333C10.0074 15.8333 4.77765 15.8333 3.47995 15.484C2.77598 15.2872 2.22588 14.7362 2.02941 14.0311C1.7841 12.704 1.6627 11.3569 1.66678 10.0073C1.66175 8.6525 1.78315 7.30023 2.02941 5.96809C2.22588 5.26299 2.77598 4.71202 3.47995 4.51524C4.79224 4.15968 10.0074 4.16664 10.0074 4.16664C10.0074 4.16664 15.2357 4.16664 16.5348 4.51524ZM8.3387 7.50096V12.5059L12.6903 10.0073L8.3387 7.50096Z'
    />
  </Icon>
)

Youtube.defaultProps = {
  originalWidth: 20,
  originalHeight: 20,
  width: 20,
  height: 20,
}

export default Youtube
