import { FC } from 'react'
import { useTheme } from 'emotion-theming'

import { Text } from 'ui'

import { TextProps } from 'ui/typography/Text/types'
import { Theme } from 'ui/theme'

interface SubtitleProps extends TextProps {
  weight?: 'semibold' | 'bold'
}

const Subtitle: FC<SubtitleProps> = ({ weight, color, children, ...props }) => {
  const theme = useTheme<Theme>()

  const lineHeights = {
    bold: theme.lineHeights.xl,
    semibold: theme.lineHeights.l,
  }

  return (
    <Text
      {...props}
      lineHeight={lineHeights[weight]}
      fontSize={theme.fontSizes.subtitle.s}
      fontWeight={theme.fontWeights[weight]}
      color={color || theme.colors.text.black}
    >
      {children}
    </Text>
  )
}

Subtitle.defaultProps = {
  weight: 'semibold',
}

export default Subtitle
