import type { FC } from 'react'
import Slider from 'react-slick'
import { styled } from 'ui/theme'
import { Box } from 'ui'

type category = {
  img: string
  title: any
  commission: string
}

interface CarouselProps {
  categories: category[]
}

const StyledSlider = styled(Slider)`
  padding-top: 140px;
  padding-right: 38px;
  padding-left: 38px;
  bottom: 105px;
`

const CarouselComponent: FC<CarouselProps> = ({ categories }) => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 300,
    slidesToShow: 6,
    slidesToScroll: 1,
    initialSlide: 0,
    nextArrow: (
      <Box width='53px' height='53px'>
        <img src='https://i.ibb.co/VTFq6t8/Riht.png' alt='right' />
      </Box>
    ),
    prevArrow: (
      <Box width='53px' height='53px'>
        <img src='https://i.ibb.co/KhwxBcz/Slider-Navigation.png' alt='left' />
      </Box>
    ),
    responsive: [
      {
        breakpoint: 1232,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
          speed: 500,
          initialSlide: 0,
          infinite: true,
          dots: false,
        },
      },
    ],
  }

  return (
    <div>
      <StyledSlider {...settings}>
        {categories.map((category, i) => (
          <Box key={i}>
            <Box display='flex' justifyContent='center' pt='7px'>
              <img src={category.img} alt='category' />
            </Box>
            <Box
              mt='10px'
              height='47px'
              display='flex'
              justifyContent='center'
              textAlign='center'
            >
              {category.title}
            </Box>
            <Box display='flex' justifyContent='center' pt='20px'>
              <img src={category.commission} alt='commission' />
            </Box>
          </Box>
        ))}
      </StyledSlider>
    </div>
  )
}

export default CarouselComponent
