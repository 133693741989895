import { FC } from 'react'
import { Icon } from 'ui/icons'
import { IconProps } from 'ui/icons/Icon'

const MI: FC<IconProps> = ({
  originalWidth,
  originalHeight,
  color,
  ...props
}) => (
  <Icon
    originalHeight={originalHeight}
    originalWidth={originalWidth}
    {...props}
  >
    <g clipPath='url(#clip0_707:18277)'>
      <path
        d='M48.3716 5.64216C43.277 0.567211 35.9517 0 27 0C18.0371 0 10.7006 0.57375 5.60819 5.66241C0.51648 10.749 0 18.0735 0 27.0263C0 35.9805 0.51648 43.3073 5.6103 48.3956C10.7024 53.485 18.038 54.0001 27 54.0001C35.9621 54.0001 43.2964 53.485 48.3885 48.3956C53.482 43.3063 54 35.9805 54 27.0263C54 18.062 53.4765 10.7295 48.3716 5.64216Z'
        fill='#FF6900'
      />
      <path
        d='M42.6678 16.7277C42.8533 16.7277 43.0077 16.8772 43.0077 17.0611V37.0354C43.0077 37.2163 42.8533 37.3665 42.6678 37.3665H38.2913C38.1034 37.3665 37.9508 37.2163 37.9508 37.0354V17.0611C37.9508 16.8773 38.1033 16.7277 38.2913 16.7277H42.6678ZM23.6752 16.7277C26.9767 16.7277 30.4287 16.8791 32.1313 18.5828C33.8052 20.2595 33.9865 23.5978 33.9934 26.8395V37.0353C33.9934 37.2162 33.8409 37.3664 33.6539 37.3664H29.278C29.0906 37.3664 28.9375 37.2162 28.9375 37.0353V26.6642C28.9328 24.8536 28.8287 22.9928 27.895 22.056C27.0913 21.2501 25.5917 21.0656 24.0322 21.0273H16.0999C15.9139 21.0273 15.7616 21.1769 15.7616 21.358V37.0354C15.7616 37.2163 15.6074 37.3665 15.4202 37.3665H11.0415C10.8544 37.3665 10.7039 37.2163 10.7039 37.0354V17.0611C10.7039 16.8773 10.8543 16.7277 11.0415 16.7277H23.6752ZM24.6547 24.6829C24.8409 24.6829 24.9923 24.832 24.9923 25.0144V37.0354C24.9923 37.2163 24.8408 37.3665 24.6547 37.3665H20.0579C19.8689 37.3665 19.7175 37.2163 19.7175 37.0354V25.0144C19.7175 24.832 19.8689 24.6829 20.0579 24.6829H24.6547Z'
        fill='white'
      />
    </g>
    <defs>
      <clipPath id='clip0_707:18277'>
        <rect width='54' height='54' fill='white' />
      </clipPath>
    </defs>
  </Icon>
)

MI.defaultProps = {
  originalWidth: 54,
  originalHeight: 54,
  width: 54,
  height: 54,
}

export default MI
