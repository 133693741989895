import { Theme } from 'ui/theme/src'

import fontSizes from 'ui/theme/src/fontSizes'
import fontFamily from 'ui/theme/src/fontFamily'
import fontWeights from 'ui/theme/src/fontWeights'
import lineHeights from 'ui/theme/src/lineHeights'
import borderRadius from 'ui/theme/src/borderRadius'
import colors, { greyColorPalette } from 'ui/theme/src/colors'

export const theme: Theme = {
  fontSizes,
  fontFamily,
  fontWeights,
  lineHeights,
  radii: borderRadius,
  colors,
  greyColorPalette,
}

export * from './types'
