import { Box, Typography, styled, theme } from 'ui'
import { Airba, AirbaMobileIcon, BurgerIcon, CloseTagIcon } from 'ui/icons'

interface IpadHeaderProps {
  successPage?: boolean
}

interface StyledBodyProps {
  last?: boolean
}

const { Body, Caption } = Typography

const Header = styled.header`
  display: flex;
  justify-content: center;
  background: #10142d;
  mix-blend-mode: normal;
  z-index: 100;
  opacity: 0.95;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12);
  height: 100px;
  @media only screen and (min-device-width: 280px) and (max-device-width: 1023px) {
    display: none;
  }
  @media only screen and (min-device-width: 1024px) and (max-device-width: 1231px) {
    display: none;
  }
`

const IpadHeader = styled.header<IpadHeaderProps>`
  display: none;
  @media only screen and (min-device-width: 1024px) and (max-device-width: 1231px) {
    display: block;
    background: #10142d;
    mix-blend-mode: normal;
    z-index: 100;
    opacity: 0.95;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12);
    height: 31px;
    padding: 33px 56px 36px 56px;
  }
`

const MobileHeader = styled.header`
  display: none;
  @media only screen and (min-device-width: 280px) and (max-device-width: 1023px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #10142d;
    padding: 14px 12px;
  }
`

const HeaderBox = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 40px 104px 39px 104px;
  width: 1232px;
  @media only screen and (min-device-width: 1024px) and (max-device-width: 1231px) {
    width: 100%;
    padding: 0;
  }
`

const StyledBody = styled(Body)<StyledBodyProps>`
  cursor: pointer;
  padding-right: ${props => (props.last ? 0 : '60px')};
  :hover {
    color: #60b3f6;
  }
  @media only screen and (min-device-width: 1024px) and (max-device-width: 1231px) {
    :hover {
      color: #fcfcfd;
    }
  }
  @media only screen and (min-device-width: 280px) and (max-device-width: 1023px) {
    :hover {
      color: #fcfcfd;
    }
  }
`

const BurgerBox = styled(Box)`
  text-align: center;
`

const HeaderBlock = ({ mobileMenu, setMobileMenu, successPage }) => {
  return (
    <>
      <Header>
        <HeaderBox>
          <a href='/'>
            <Airba width={164} height={21} />
          </a>
          {!successPage && (
            <nav>
              <a href='#advantages'>
                <StyledBody color='text.white'>Преимущества</StyledBody>
              </a>
              <a href='#stages'>
                <StyledBody color='text.white'>Этапы</StyledBody>
              </a>
              <a href='#partners'>
                <StyledBody color='text.white'>Партнеры</StyledBody>
              </a>
              <a href='#category'>
                <StyledBody color='text.white'>Категории</StyledBody>
              </a>
              <a href='#register'>
                <StyledBody last color='text.white'>
                  Регистрация
                </StyledBody>
              </a>
            </nav>
          )}
        </HeaderBox>
      </Header>

      <IpadHeader>
        <HeaderBox>
          <img src='https://i.ibb.co/23bvdrW/Vector.png' alt='Airba' />
          {!successPage && (
            <Box display='flex' justifyContent='space-between'>
              <a href='#ipad-advantages'>
                <StyledBody color='text.white'>Преимущества</StyledBody>
              </a>
              <a href='#ipad-stages'>
                <StyledBody color='text.white'>Этапы</StyledBody>
              </a>
              <a href='#ipad-partners'>
                <StyledBody color='text.white'>Партнеры</StyledBody>
              </a>
              <a href='#ipad-category'>
                <StyledBody color='text.white'>Категории</StyledBody>
              </a>
              <a href='#ipad-register'>
                <StyledBody color='text.white'>Регистрация</StyledBody>
              </a>
            </Box>
          )}
        </HeaderBox>
      </IpadHeader>

      <MobileHeader>
        <Box pl='12px' pt='4.5px'>
          <AirbaMobileIcon />
        </Box>
        <BurgerBox onClick={() => setMobileMenu(!mobileMenu)}>
          <BurgerIcon />
          <Box>
            <Caption
              level={2}
              weight='semibold'
              color={theme.greyColorPalette[0]}
            >
              Меню
            </Caption>
          </Box>
        </BurgerBox>
        {mobileMenu && (
          <Box
            position='absolute'
            backgroundColor={theme.greyColorPalette[0]}
            height='295px'
            width='100%'
            left={0}
            right={0}
            top={77}
            zIndex={100}
            p='24px'
          >
            <Box
              display='flex'
              justifyContent='right'
              onClick={() => setMobileMenu(false)}
            >
              <CloseTagIcon />
            </Box>
            <nav>
              <Box my='24px'>
                <a href='#mobile-advantages'>
                  <Body weight='semibold' color='text.black'>
                    Преимущества
                  </Body>
                </a>
              </Box>
              <Box mb='24px'>
                <a href='#mobile-stages'>
                  <Body weight='semibold' color='text.black'>
                    Этапы
                  </Body>
                </a>
              </Box>
              <Box mb='24px'>
                <a href='#mobile-partners'>
                  <Body weight='semibold' color='text.black'>
                    Партнеры
                  </Body>
                </a>
              </Box>
              <Box mb='24px'>
                <a href='#mobile-category'>
                  <Body weight='semibold' color='text.black'>
                    Категории
                  </Body>
                </a>
              </Box>
              <Box mb='24px'>
                <a href='#mobile-register'>
                  <Body weight='semibold' color='text.black'>
                    Регистрация
                  </Body>
                </a>
              </Box>
            </nav>
          </Box>
        )}
      </MobileHeader>
    </>
  )
}

export default HeaderBlock
