import { ThemeFontWeights } from './types'

const fontWeights: ThemeFontWeights = {
  light: 300,
  regular: 400,
  medium: 500,
  semibold: 600,
  bold: 700,
  black: 800,
}

export default fontWeights
