import { FC } from 'react'
import { Icon } from 'ui/icons'
import { IconProps } from 'ui/icons/Icon'

const Twitter: FC<IconProps> = ({
  originalWidth,
  originalHeight,
  color,
  ...props
}) => (
  <Icon
    originalHeight={originalHeight}
    originalWidth={originalWidth}
    {...props}
  >
    <g clip-path='url(#clip0_1613_26059)'>
      <path
        d='M17.5 5.34792C16.948 5.59266 16.355 5.7581 15.7325 5.83249C16.3678 5.45163 16.8558 4.84856 17.0856 4.12991C16.4909 4.48261 15.8324 4.73869 15.1314 4.87668C14.57 4.27859 13.7702 3.90491 12.8851 3.90491C11.1855 3.90491 9.80767 5.2827 9.80767 6.98221C9.80767 7.22341 9.83488 7.45828 9.88735 7.68354C7.32975 7.55517 5.06215 6.33003 3.54434 4.46814C3.27944 4.92265 3.12765 5.45128 3.12765 6.01527C3.12765 7.08296 3.67093 8.02491 4.49669 8.57677C3.99223 8.56078 3.51771 8.42235 3.10284 8.19184C3.10255 8.20468 3.10255 8.21757 3.10255 8.23054C3.10255 9.72157 4.16335 10.9653 5.57112 11.2481C5.31293 11.3184 5.04102 11.3561 4.76036 11.3561C4.56205 11.3561 4.36928 11.3368 4.18134 11.3008C4.57298 12.5234 5.70946 13.4132 7.05611 13.438C6.0029 14.2635 4.67596 14.7554 3.23412 14.7554C2.98571 14.7554 2.74076 14.7408 2.5 14.7123C3.86189 15.5855 5.47951 16.095 7.21739 16.095C12.8778 16.095 15.9733 11.4057 15.9733 7.33902C15.9733 7.2056 15.9702 7.07288 15.9643 6.94087C16.5657 6.50702 17.0874 5.96503 17.5 5.34792Z'
        fill='#10142D'
      />
    </g>
    <defs>
      <clipPath id='clip0_1613_26059'>
        <rect
          width='15'
          height='15'
          fill='white'
          transform='translate(2.5 2.5)'
        />
      </clipPath>
    </defs>
  </Icon>
)

Twitter.defaultProps = {
  originalWidth: 20,
  originalHeight: 20,
  width: 20,
  height: 20,
}

export default Twitter
