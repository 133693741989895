import { FC } from 'react'
import { Icon } from 'ui/icons'
import { IconProps } from 'ui/icons/Icon'

const Lock: FC<IconProps> = ({ color, ...props }) => (
  <Icon {...props}>
    <path
      d='M15 6.66665H14.1667V4.99998C14.1667 2.69998 12.3 0.833313 10 0.833313C7.70004 0.833313 5.83337 2.69998 5.83337 4.99998V6.66665H5.00004C4.08337 6.66665 3.33337 7.41665 3.33337 8.33331V16.6666C3.33337 17.5833 4.08337 18.3333 5.00004 18.3333H15C15.9167 18.3333 16.6667 17.5833 16.6667 16.6666V8.33331C16.6667 7.41665 15.9167 6.66665 15 6.66665ZM7.50004 4.99998C7.50004 3.61665 8.61671 2.49998 10 2.49998C11.3834 2.49998 12.5 3.61665 12.5 4.99998V6.66665H7.50004V4.99998ZM15 16.6666H5.00004V8.33331H15V16.6666ZM10 14.1666C10.9167 14.1666 11.6667 13.4166 11.6667 12.5C11.6667 11.5833 10.9167 10.8333 10 10.8333C9.08337 10.8333 8.33337 11.5833 8.33337 12.5C8.33337 13.4166 9.08337 14.1666 10 14.1666Z'
      fill={color}
    />
  </Icon>
)

Lock.defaultProps = {
  originalWidth: 20,
  originalHeight: 20,
  width: 20,
  height: 20,
  color: '#888A96',
}

export default Lock
