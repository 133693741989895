import { FC } from 'react'
import { useTheme } from 'emotion-theming'

import { VisibleOffIcon, VisibleOnIcon } from 'ui/icons'

import { Theme } from 'ui/theme'

interface PasswordVisibilityIconProps {
  onEyeClick: VoidFunction
  type: string
}

const PasswordVisibilityIcon: FC<PasswordVisibilityIconProps> = ({
  onEyeClick,
  type,
}) => {
  const theme = useTheme<Theme>()

  return (
    <div className='__form-field-icon' onClick={onEyeClick}>
      {type === 'password' ? (
        <VisibleOffIcon color={theme.colors.icon.black} />
      ) : (
        <VisibleOnIcon color={theme.colors.icon.black} />
      )}
    </div>
  )
}

export default PasswordVisibilityIcon
