import { createElement, FC } from 'react'

import { Text } from 'ui'
import { TextProps } from 'ui/typography/Text/types'

interface TruncateProps {
  limit: number
  children: string
}

const Truncate: FC<TruncateProps & TextProps> = ({
  limit,
  children,
  ...rest
}) =>
  createElement(
    Text,
    { ...rest, title: children },
    children.length > limit
      ? children.substring(0, limit - 3) + '...'
      : children,
  )

export default Truncate
