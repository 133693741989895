import { Body } from 'ui/typography/Typography/Body'
import { Main } from 'ui/typography/Typography/Main'
import { Title } from 'ui/typography/Typography/Title'
import { Caption } from 'ui/typography/Typography/Caption'
import { Subtitle } from 'ui/typography/Typography/Subtitle'

import { Text } from 'ui'
import { TypographyProps } from 'ui/typography/Typography/types'

const Typography: TypographyProps = ({ children, ...props }) => (
  <Text {...props}>{children}</Text>
)

Typography.Body = Body
Typography.Body.displayName = 'Typography.Body'

Typography.Title = Title
Typography.Title.displayName = 'Typography.Title'

Typography.Caption = Caption
Typography.Caption.displayName = 'Typography.Caption'

Typography.Subtitle = Subtitle
Typography.Subtitle.displayName = 'Typography.Subtitle'

Typography.Main = Main
Typography.Main.displayName = 'Typography.Main'

export default Typography
