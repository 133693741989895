import { FC } from 'react'

import { LoadingWrap } from 'ui/icons/Loading/styles'
import { LoadingProps } from 'ui/icons/Loading/types'

const Loading: FC<LoadingProps> = ({ color }) => (
  <LoadingWrap color={color}>
    <div className='lds-ellipsis'>
      <div />
      <div />
      <div />
      <div />
    </div>
  </LoadingWrap>
)

Loading.defaultProps = {
  color: '#fff',
}

export default Loading
