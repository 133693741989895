import { ThemeFontSizes } from 'ui/theme/src'

const fontSizes: ThemeFontSizes = {
  title: {
    s: '32px',
    m: '40px',
    l: '56px',
  },
  subtitle: {
    s: '20px',
  },
  body: {
    s: '14px',
    m: '15px',
  },
  caption: {
    s: '10px',
    m: '12px',
  },
  button: {
    n: '8px',
    m: '10px',
    l: '12px',
    xl: '20px',
  },
}

export default fontSizes
