import { FC } from 'react'
import { useTheme } from 'emotion-theming'

import { Text } from 'ui'

import { TextProps } from 'ui/typography/Text/types'
import { Theme } from 'ui/theme'

interface TitleProps extends TextProps {
  level?: 1 | 2 | 3
}

const Title: FC<TitleProps> = ({ level, color, children, ...props }) => {
  const theme = useTheme<Theme>()

  const levels = {
    1: {
      fontSize: theme.fontSizes.title.l,
      fontWeight: theme.fontWeights.black,
      lineHeight: theme.lineHeights.xxl[2],
    },
    2: {
      fontSize: theme.fontSizes.title.m,
      fontWeight: theme.fontWeights.bold,
      lineHeight: theme.lineHeights.xxl[1],
    },
    3: {
      fontSize: theme.fontSizes.title.s,
      fontWeight: theme.fontWeights.semibold,
      lineHeight: theme.lineHeights.xxl[0],
    },
  }

  return (
    <Text
      {...props}
      fontSize={levels[level].fontSize}
      fontWeight={levels[level].fontWeight}
      lineHeight={levels[level].lineHeight}
      color={color || theme.colors.text.black}
    >
      {children}
    </Text>
  )
}

Title.defaultProps = {
  level: 3,
}

export default Title
