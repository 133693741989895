import { styled } from 'ui/theme'

import type { FC, ReactNode } from 'react'

const A = styled.a`
  color: ${props => props.theme.colors.text.link};
`

interface Props {
  href: string
  children: ReactNode
}

const Anchor: FC<Props> = ({ href, children }) => {
  return <A href={href}>{children}</A>
}

export { Anchor }
