import { Box, styled, theme, Typography } from 'ui'
import {
  AppGalleryIcon,
  GooglePlayIcon,
  InstagramIcon,
  AppStoreIcon,
  FacebookIcon,
  TelegramIcon,
  YoutubeIcon,
  TikTokIcon,
} from 'ui/icons'

import { ContentBox } from '../components'

const Footer = styled.footer`
  background: #f3f4fb;
  height: 176px;
  padding-top: 48px;
  padding-bottom: 44px;
  @media only screen and (min-device-width: 280px) and (max-device-width: 1023px) {
    display: none;
  }
`

const MobileFooter = styled.footer`
  display: none;
  @media only screen and (min-device-width: 280px) and (max-device-width: 1023px) {
    display: block;
    text-align: center;
    padding: 24px;
    background: #f3f4fb;
    height: 587px;
  }
`

const FooterDesc = styled(Box)`
  text-align: left;
`

const { Body, Caption } = Typography

const FooterBlock = () => {
  return (
    <>
      <Footer>
        <ContentBox>
          <img src='https://i.ibb.co/1bYSrJm/Vector-1.png' alt='Airba' />
          <Box display='flex' justifyContent='space-between'>
            <Box>
              <Body
                weight='semibold'
                color={theme.colors.text.black}
                as='p'
                pb='8px'
                pt='16px'
              >
                Почта
              </Body>
              <Box>
                <a href='mailto:partner.support@airba.kz' target='merchant'>
                  <Body
                    weight='semibold'
                    color={theme.colors.text.black}
                    as='p'
                  >
                    partner.support@airba.kz
                  </Body>
                </a>
              </Box>
            </Box>
            <Box>
              <Body
                weight='semibold'
                color={theme.colors.text.black}
                as='p'
                pb='8px'
                pt='16px'
              >
                Соцсети
              </Body>
              <Box
                display='flex'
                alignItems='center'
                justifyContent='space-between'
                width='160px'
              >
                <a
                  href='https://www.facebook.com/airba.kz'
                  target='_blank'
                  rel='noreferrer'
                  style={{ height: '20px' }}
                >
                  <FacebookIcon />
                </a>
                <a
                  href='https://www.instagram.com/airba.kz/'
                  target='_blank'
                  rel='noreferrer'
                  style={{ height: '20px' }}
                >
                  <InstagramIcon />
                </a>
                <a
                  href='https://www.tiktok.com/@airba.kz'
                  target='_blank'
                  rel='noreferrer'
                  style={{ height: '20px' }}
                >
                  <TikTokIcon />
                </a>
                <a
                  href='https://www.youtube.com/channel/UCEgrmbS9pEJ9XP1Oi_BxYEw'
                  target='_blank'
                  rel='noreferrer'
                  style={{ height: '20px' }}
                >
                  <YoutubeIcon />
                </a>
                <a
                  href='https://t.me/Airba_service_bot'
                  target='_blank'
                  rel='noreferrer'
                  style={{ height: '20px' }}
                >
                  <TelegramIcon />
                </a>
              </Box>
            </Box>
            <Box>
              <Body
                weight='semibold'
                color={theme.colors.text.black}
                as='p'
                pb='8px'
                pt='16px'
              >
                Наше приложение:
              </Body>
              <Box mb='12px' display='flex' alignItems='center'>
                <a
                  href='https://apps.apple.com/kz/app/airba/id1609020292'
                  target='_blank'
                  rel='noreferrer'
                >
                  <AppStoreIcon />
                </a>
                <Box ml='14px'>
                  <a
                    href='https://play.google.com/store/apps/details?id=kz.technodom.airba&hl=ru&gl=KZ'
                    target='_blank'
                    rel='noreferrer'
                  >
                    <GooglePlayIcon />
                  </a>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box mt='16px'>
            <Caption color='rgba(16, 20, 45, 1)' level={2}>
              2022 © AIRBA — мультитоварный маркетплейс техники, одежды, обуви и
              аксессуаров. Все права защищены.
            </Caption>
          </Box>
        </ContentBox>
      </Footer>

      <MobileFooter>
        <Body
          weight='semibold'
          color={theme.colors.text.black}
          as='p'
          mb='13px'
        >
          Почта
        </Body>
        <a href='mailto:partner.support@airba.kz' target='merchant'>
          <Caption color='rgba(16, 20, 45, 0.8)' level={1}>
            partner.support@airba.kz
          </Caption>
        </a>
        <Body
          weight='semibold'
          color={theme.colors.text.black}
          as='p'
          mt='24px'
          mb='13px'
        >
          Соцсети
        </Body>
        <Box display='flex' justifyContent='center' mb='24px'>
          <Box
            display='flex'
            alignItems='center'
            justifyContent='space-between'
            width='160px'
          >
            <a
              href='https://www.facebook.com/airba.kz'
              target='_blank'
              rel='noreferrer'
              style={{ height: '20px' }}
            >
              <FacebookIcon />
            </a>
            <a
              href='https://www.instagram.com/airba.kz/'
              target='_blank'
              rel='noreferrer'
              style={{ height: '20px' }}
            >
              <InstagramIcon />
            </a>
            <a
              href='https://www.tiktok.com/@airba.kz'
              target='_blank'
              rel='noreferrer'
              style={{ height: '20px' }}
            >
              <TikTokIcon />
            </a>
            <a
              href='https://www.youtube.com/channel/UCEgrmbS9pEJ9XP1Oi_BxYEw'
              target='_blank'
              rel='noreferrer'
              style={{ height: '20px' }}
            >
              <YoutubeIcon />
            </a>
            <a
              href='https://t.me/Airba_service_bot'
              target='_blank'
              rel='noreferrer'
              style={{ height: '20px' }}
            >
              <TelegramIcon />
            </a>
          </Box>
        </Box>
        <Body
          weight='semibold'
          color={theme.colors.text.black}
          as='p'
          pb='15px'
        >
          Наше приложение:
        </Body>
        <Box mb='24px'>
          <Box mb='37px'>
            <a
              href='https://apps.apple.com/kz/app/airba/id1609020292'
              target='_blank'
              rel='noreferrer'
            >
              <AppStoreIcon width={169} height={50} />
            </a>
          </Box>
          <Box mb='37px'>
            <a
              href='https://play.google.com/store/apps/details?id=kz.technodom.airba&hl=ru&gl=KZ'
              target='_blank'
              rel='noreferrer'
            >
              <GooglePlayIcon width={169} height={50} />
            </a>
          </Box>
          <Box>
            <AppGalleryIcon width={169} height={50} />
          </Box>
        </Box>
        <FooterDesc>
          <Caption color='rgba(16, 20, 45, 1)' level={2}>
            2022 © AIRBA — мультитоварный маркетплейс техники, одежды, обуви и
            аксессуаров. Все права защищены.
          </Caption>
        </FooterDesc>
      </MobileFooter>
    </>
  )
}

export default FooterBlock
