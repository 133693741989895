import { Box, Typography, theme } from 'ui'
import {
  Section,
  IpadSection,
  MobileSection,
  ContentBox,
  MainText,
} from '../components'

const { Body, Subtitle } = Typography

const StagesSection = () => {
  return (
    <>
      <Section background={theme.colors.main.background.white} id='stages'>
        <ContentBox py='48px'>
          <MainText>Подключение в 3 шага</MainText>
          <Box mt='44px'>
            <Box display='flex' alignItems='center'>
              <img
                width='53px'
                height='53px'
                src='https://i.ibb.co/QCYhwt1/number-1.png'
                alt='1'
              />
              <Box pl='22px'>
                <Subtitle>Заполните форму регистрации</Subtitle>
              </Box>
            </Box>
            <Box display='flex' alignItems='center' mt='48px'>
              <img
                width='53px'
                height='53px'
                src='https://i.ibb.co/NtkhPM2/number-2.png'
                alt='2'
              />
              <Box pl='22px'>
                <Subtitle>
                  Приложите подписанное заявление о присоединении
                </Subtitle>
              </Box>
            </Box>
            <Box display='flex' alignItems='center' mt='48px'>
              <img
                width='53px'
                height='53px'
                src='https://i.ibb.co/3mjWJD0/number-3.png'
                alt='3'
              />
              <Box pl='22px'>
                <Subtitle>
                  Загрузите прайс-лист с товарами и ждите продаж
                </Subtitle>
              </Box>
            </Box>
          </Box>
        </ContentBox>
      </Section>

      <IpadSection
        background={theme.colors.main.background.white}
        id='ipad-stages'
      >
        <ContentBox py='48px'>
          <MainText>Подключение в 3 шага</MainText>
          <Box mt='44px'>
            <Box display='flex' alignItems='center'>
              <img
                width='53px'
                height='53px'
                src='https://i.ibb.co/QCYhwt1/number-1.png'
                alt='1'
              />
              <Box pl='22px'>
                <Subtitle>Заполните форму регистрации</Subtitle>
              </Box>
            </Box>
            <Box display='flex' alignItems='center' mt='48px'>
              <img
                width='53px'
                height='53px'
                src='https://i.ibb.co/NtkhPM2/number-2.png'
                alt='2'
              />
              <Box pl='22px'>
                <Subtitle>
                  Приложите подписанное заявление о присоединении
                </Subtitle>
              </Box>
            </Box>
            <Box display='flex' alignItems='center' mt='48px'>
              <img
                width='53px'
                height='53px'
                src='https://i.ibb.co/3mjWJD0/number-3.png'
                alt='3'
              />
              <Box pl='22px'>
                <Subtitle>
                  Загрузите прайс-лист с товарами и ждите продаж
                </Subtitle>
              </Box>
            </Box>
          </Box>
        </ContentBox>
      </IpadSection>

      <MobileSection
        background={theme.colors.main.background.white}
        id='mobile-stages'
      >
        <ContentBox py='24px'>
          <MainText mb='24px'>Подключение в 3 шага</MainText>
          <Box display='flex' mb='48px'>
            <img
              width='53px'
              height='53px'
              src='https://i.ibb.co/QCYhwt1/number-1.png'
              alt='1'
            />
            <Box pl='22px'>
              <Body weight='semibold'>Заполните форму регистрации</Body>
            </Box>
          </Box>
          <Box display='flex' mb='48px'>
            <img
              width='53px'
              height='53px'
              src='https://i.ibb.co/NtkhPM2/number-2.png'
              alt='2'
            />
            <Box pl='22px'>
              <Body weight='semibold'>
                Приложите подписанное заявление о присоединении
              </Body>
            </Box>
          </Box>
          <Box display='flex'>
            <img
              width='53px'
              height='53px'
              src='https://i.ibb.co/3mjWJD0/number-3.png'
              alt='3'
            />
            <Box pl='22px'>
              <Body weight='semibold'>
                Загрузите прайс-лист с товарами и ждите продаж
              </Body>
            </Box>
          </Box>
        </ContentBox>
      </MobileSection>
    </>
  )
}

export default StagesSection
