import { Box, styled, theme, Typography } from 'ui'
import { Check } from 'ui/icons'
import {
  Section,
  IpadSection,
  MobileSection,
  ContentBox,
  ScrollBox,
  MainText,
} from '../components'

interface ServiceContentProps {
  mt?: string
}

interface AdvantagesBoxProps {
  mr?: string
}

const { Subtitle, Caption } = Typography

const AdvantagesBoxes = styled(Box)`
  margin-top: 48px;
  @media only screen and (min-device-width: 280px) and (max-device-width: 1023px) {
    margin-top: 0;
  }
`

const AdvantagesBox = styled(Box)<AdvantagesBoxProps>`
  height: 510px;
  margin-right: ${props => (props.mr ? props.mr : 0)};
  @media only screen and (min-device-width: 280px) and (max-device-width: 1023px) {
    margin-right: 24px;
  }
`

const ServiceContent = styled(Box)<ServiceContentProps>`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #fcfcfd;
  margin-top: ${props => (props.mt ? props.mt : '24px')};
  @media only screen and (min-device-width: 280px) and (max-device-width: 1023px) {
    margin-top: ${props => (props.mt ? props.mt : '24px')};
  }
`

const LinkCaption = styled(Caption)`
  text-decoration: underline;
  cursor: pointer;
  white-space: nowrap;
  opacity: 0.7;
`

const Advantages = () => (
  <AdvantagesBoxes
    display='flex'
    alignItems='center'
    justifyContent='space-between'
  >
    <AdvantagesBox
      border='2px solid rgba(49, 156, 243, 0.5)'
      borderRadius='24px'
      width='303px'
      padding='28px'
    >
      <Box>
        <Subtitle color={theme.colors.text.white}>Подключение</Subtitle>
      </Box>
      <Box display='flex' justifyContent='center' paddingTop='38px'>
        <img
          src='https://i.ibb.co/L69fRPv/0070-1.png'
          width='186'
          height='135'
          alt='logistics-logo'
        />
      </Box>
      <ServiceContent display='flex' alignItems='top' mt='28px'>
        <Box marginRight='15px' minWidth='24px'>
          <Check />
        </Box>
        Заполните форму регистрации.
      </ServiceContent>
      <ServiceContent display='flex' alignItems='top'>
        <Box marginRight='15px' minWidth='24px'>
          <Check />
        </Box>
        Загрузите прайс лист товаров. <br /> Мы проверим и разместим его для
        продажи.
      </ServiceContent>
      <ServiceContent display='flex' alignItems='top'>
        <Box marginRight='15px' minWidth='24px'>
          <Check />
        </Box>
        Закрепим за вами персонального менеджера для решения всех вопросов
      </ServiceContent>
    </AdvantagesBox>

    <AdvantagesBox
      border='2px solid rgba(49, 156, 243, 0.5)'
      borderRadius='24px'
      width='303px'
      padding='28px'
    >
      <Box>
        <Subtitle color={theme.colors.text.white}>Доставка</Subtitle>
      </Box>
      <Box display='flex' justifyContent='center' paddingTop='56px'>
        <img
          src='https://i.ibb.co/QkTSV84/2022.png'
          width='186'
          height='117'
          alt='logistics-logo'
        />
      </Box>
      <ServiceContent display='flex' alignItems='top' mt='28px'>
        <Box marginRight='15px'>
          <Check />
        </Box>
        Заберём у вас товары <br />
        на доставку
      </ServiceContent>
      <ServiceContent display='flex' alignItems='top'>
        <Box marginRight='15px'>
          <Check />
        </Box>
        <Caption color='#FCFCFD'>
          Доставим заказы вашим
          <br />
          клиентам на следующий день
          <br />— вы платите за доставку
          <br />
          по заказам, а не по товарам
          <br />
          <br />
          <a
            href='https://cabinet.airba.kz/faq/tarify-na-dostavku'
            target='_blank'
            rel='noreferrer'
          >
            <LinkCaption color='#60B3F6'>
              Тарифы на хранение и доставку
            </LinkCaption>
          </a>
        </Caption>
      </ServiceContent>
    </AdvantagesBox>

    <AdvantagesBox
      border='2px solid rgba(49, 156, 243, 0.5)'
      borderRadius='24px'
      width='303px'
      padding='28px'
    >
      <Box>
        <Subtitle color={theme.colors.text.white}>Финансы</Subtitle>
      </Box>
      <Box display='flex' justifyContent='center' paddingTop='46px'>
        <img
          src='https://i.ibb.co/Hq2sy1R/2024.png'
          width='186'
          height='116'
          alt='logistics-logo'
        />
      </Box>
      <ServiceContent display='flex' alignItems='top' mt='39px'>
        <Box marginRight='15px'>
          <Check />
        </Box>
        Предлагаем вашим клиентам
        <br />
        покупать в кредит и рассрочку
        <br />— деньги вы получаете сразу.
        <br />
        Мы работаем с Airba Pay,
        <br />
        Freedom Finance, Halyk Bank
      </ServiceContent>
      <ServiceContent display='flex' alignItems='top' mt='31px'>
        <Box marginRight='15px'>
          <Check />
        </Box>
        Выводим заработанные деньги
        <br />
        на ваш банковский счет
        <br />
        компании в любом банке
      </ServiceContent>
    </AdvantagesBox>
  </AdvantagesBoxes>
)

const AdvantagesSection = () => {
  return (
    <>
      <Section background={theme.colors.main.background.black} id='advantages'>
        <ContentBox py='48px'>
          <MainText>Организуем всё за вас</MainText>
          <Advantages />
        </ContentBox>
      </Section>

      <IpadSection
        background={theme.colors.main.background.black}
        id='ipad-advantages'
      >
        <ContentBox py='48px'>
          <MainText>Организуем всё за вас</MainText>
          <Advantages />
        </ContentBox>
      </IpadSection>

      <MobileSection
        background={theme.colors.main.background.black}
        id='mobile-advantages'
      >
        <MainText p='24px 24px 0 24px'>Организуем всё за вас</MainText>
        <ScrollBox p='24px'>
          <Advantages />
        </ScrollBox>
      </MobileSection>
    </>
  )
}

export default AdvantagesSection
