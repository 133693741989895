import { Box, Button, Typography, theme, styled } from 'ui'
import { Elements } from 'ui/icons'
import {
  Section,
  IpadSection,
  MobileSection,
  ContentBox,
  MainText,
} from '../components'

const { Body, Subtitle } = Typography

const BodySemiboldBox = styled(Box)`
  display: none;
  @media only screen and (min-device-width: 360px) and (max-device-width: 1023px) {
    display: block;
  }
`

const StartSection = () => {
  return (
    <>
      <Section background={theme.colors.main.background.white}>
        <ContentBox>
          <MainText pt='105px'>
            Продавайте <br />
            на Airba
          </MainText>
          <Box py='44px'>
            <Subtitle>
              С вас хорошие товары, <br />с нас — сервис и доставка
            </Subtitle>
          </Box>
          <a href='#register'>
            <Button mb='80px'>Регистрация</Button>
          </a>
          <Box position='absolute' top={0} right={13} bottom={16} zIndex={10}>
            <Elements width={842} height={518} />
          </Box>
          <Box position='absolute' bottom='-50%' right='-36%'>
            <img
              src='https://i.ibb.co/LS7XFY2/Rectangle-475-1.png'
              alt='rectangle'
            />
          </Box>
          <Box position='absolute' top={160} right={118}>
            <img
              src='https://i.ibb.co/3Tvsdh0/plain.png'
              width='519'
              height='328'
              alt='plain-logo'
            />
          </Box>
        </ContentBox>
      </Section>

      <IpadSection background={theme.colors.main.background.white}>
        <ContentBox py='60px'>
          <Box position='absolute' top={0} right='-45%'>
            <img
              src='https://i.ibb.co/cX9RprS/Rectangle-bg.png'
              alt='rectangle'
            />
          </Box>
          <Box position='absolute' top={160} right={90}>
            <img
              src='https://i.ibb.co/3Tvsdh0/plain.png'
              width='519'
              height='328'
              alt='plain-logo'
            />
          </Box>
          <MainText>
            Продавайте <br />
            на Airba
          </MainText>
          <Box py='44px'>
            <Subtitle>
              С вас хорошие товары, <br />с нас — сервис и доставка
            </Subtitle>
          </Box>
          <a href='#ipad-register'>
            <Button>Регистрация</Button>
          </a>
        </ContentBox>
      </IpadSection>

      <MobileSection
        height='640px'
        background={theme.colors.main.background.white}
      >
        <Box zIndex={1} position='absolute' top='290px' right='26px'>
          <img
            src='https://i.ibb.co/mNZzfYt/elements-object-1.png'
            alt='mobile-elements'
          />
        </Box>
        <Box position='absolute' bottom={-4} right={0}>
          <img
            src='https://i.ibb.co/cNjvJ4z/Rectangle-505.png'
            alt='rectangle-mobile'
          />
        </Box>
        <ContentBox>
          <MainText>
            Продавайте <br />
            на Airba
          </MainText>
          <BodySemiboldBox py='24px'>
            <Body weight='semibold'>
              С вас хорошие товары, <br />с нас — сервис и доставка
            </Body>
          </BodySemiboldBox>
          <a href='#mobile-register'>
            <Button fullWidth>Регистрация</Button>
          </a>
          <Box zIndex={12} my='24px'>
            <img
              style={{ zIndex: 10 }}
              src='https://i.ibb.co/7r7ZmNv/0126-1-1.png'
              width='312'
              height='282'
              alt='plain-logo'
            />
          </Box>
        </ContentBox>
      </MobileSection>
    </>
  )
}

export default StartSection
