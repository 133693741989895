import { FC } from 'react'
import { useTheme } from 'emotion-theming'

import { CloseIcon } from 'ui/icons'
import { Box } from 'ui'

import { Theme } from 'ui/theme'

interface ClearValueIconProps {
  onClear: VoidFunction
}

const ClearValueIcon: FC<ClearValueIconProps> = ({ onClear }) => {
  const theme = useTheme<Theme>()

  return (
    <Box className='__form-field-icon' onClick={onClear}>
      <CloseIcon color={theme.colors.icon.secondaryBlack} />
    </Box>
  )
}

export default ClearValueIcon
