import styled from 'ui/theme/styled'
import { theme } from 'ui/theme'
import shouldForwardProp from '@styled-system/should-forward-prop'
import {
  flexbox,
  grid,
  space,
  layout,
  border,
  color,
  position,
  textAlign,
  SpaceProps,
  FlexboxProps,
  LayoutProps,
  GridProps,
  BorderProps,
  PositionProps,
  BackgroundColorProps,
  TextAlignProps,
} from 'styled-system'

type BoxProps = PositionProps &
  LayoutProps &
  GridProps &
  FlexboxProps &
  SpaceProps &
  BorderProps &
  BackgroundColorProps &
  TextAlignProps

const Box = styled('div', { shouldForwardProp })<BoxProps>`
  box-sizing: border-box;
  min-width: 0;
  ${position}
  ${layout}
  ${grid}
  ${flexbox}
  ${space}
  ${border}
  ${color}
  ${textAlign}
`

Box.defaultProps = {
  theme,
}

export default Box
