import { css, SerializedStyles } from '@emotion/core'
import { ISizes } from 'ui/button/types'

export const sizes = ({ size, theme, variant }: ISizes): SerializedStyles => {
  const nano = css`
    height: 24px;
    padding: 0 8px;
    font-size: ${theme.fontSizes.button.n};
    line-height: ${theme.lineHeights.xxs};
    ${variant === 'default' &&
    `
      padding: 0 7px;
    `}

    svg {
      width: 12px;
      height: 12px;
    }
  `

  const medium = css`
    height: 40px;
    padding: 0 12px;
    font-size: ${theme.fontSizes.button.l};
    line-height: ${theme.lineHeights.m};
    ${variant === 'default' &&
    `
      padding: 0 11px;
    `}

    svg {
      width: 20px;
      height: 20px;
    }
  `

  const large = css`
    height: 64px;
    padding: 0 20px;
    font-size: ${theme.fontSizes.button.xl};
    line-height: ${theme.lineHeights.m};
    ${variant === 'default' &&
    `
      padding: 0 19px;
    `}

    svg {
      width: 20px;
      height: 20px;
    }
  `

  const sizeNames = {
    nano,
    medium,
    large,
  }

  return sizeNames[size]
}
