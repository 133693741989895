import { Box, styled } from 'ui'

interface MainTextProps {
  alignItems?: string
}

interface SectionProps {
  height?: string
  background: string
}

export const Section = styled.section<SectionProps>`
  height: ${props => (props.height ? props.height : '100%')};
  background: ${props => props.background};
  position: relative;
  @media only screen and (min-device-width: 280px) and (max-device-width: 1023px) {
    display: none;
  }
  @media only screen and (min-device-width: 1024px) and (max-device-width: 1231px) {
    display: none;
  }
`

export const IpadSection = styled.section<SectionProps>`
  display: none;
  @media only screen and (min-device-width: 1024px) and (max-device-width: 1231px) {
    position: relative;
    display: block;
    background: ${props => props.background};
    height: ${props => (props.height ? props.height : '100%')};
  }
`

export const MobileSection = styled.section<SectionProps>`
  display: none;
  @media only screen and (min-device-width: 280px) and (max-device-width: 1023px) {
    position: relative;
    display: block;
    background: ${props => props.background};
    height: ${props => (props.height ? props.height : '100%')};
  }
`

export const ContentBox = styled(Box)`
  width: 1232px;
  height: 100%;
  position: relative;
  padding-right: 104px;
  padding-left: 104px;
  margin-left: auto;
  margin-right: auto;
  @media only screen and (min-device-width: 280px) and (max-device-width: 1023px) {
    padding: 24px;
    width: 100%;
  }
  @media only screen and (min-device-width: 1024px) and (max-device-width: 1231px) {
    width: 100%;
    padding-right: 56px;
    padding-left: 56px;
  }
`

export const MainText = styled(Box)<MainTextProps>`
  font-size: 56px;
  line-height: 72px;
  font-weight: 800;
  align-items: ${props => (props.alignItems ? props.alignItems : 'inherit')};
  background: linear-gradient(to right, #b346b0, #605cd7);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  @media only screen and (min-device-width: 280px) and (max-device-width: 1023px) {
    font-weight: bold;
    font-size: 32px;
    line-height: 48px;
  }
`

export const ScrollBox = styled(Box)`
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  &::-webkit-scrollbar {
    display: none;
  }
  -webkit-overflow-scrolling: touch;
  > * {
    flex: 0 0 auto;
  }
`
