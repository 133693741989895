import { Route, Switch } from 'react-router-dom'

import { Main } from 'modules/main/pages'
import { SuccessRegistration } from 'modules/main/pages'

const App = () => {
  return (
    <Switch>
      <Route exact path='/' render={() => <Main />} />
      <Route
        path='/success-registration'
        render={props => <SuccessRegistration {...props} />}
      />
    </Switch>
  )
}

export default App
