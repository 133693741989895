import { createElement, Fragment, FC } from 'react'

import { ConditionProps } from 'ui/condition/types'

const Condition: FC<ConditionProps> = ({ children, match }) => {
  if (match) {
    return createElement(Fragment, {}, children)
  }

  return null
}

export default Condition
