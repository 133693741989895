import { FC } from 'react'
import { useTheme } from 'emotion-theming'

import { Text } from 'ui'

import { TextProps } from 'ui/typography/Text/types'
import { Theme } from 'ui/theme'

interface BodyProps extends TextProps {
  level?: 1 | 2
  weight?: 'regular' | 'semibold' | 'bold'
}

const Body: FC<BodyProps> = ({ level, weight, color, children, ...props }) => {
  const theme = useTheme<Theme>()

  const levels = {
    1: {
      fontSize: theme.fontSizes.body.m,
      lineHeight: theme.lineHeights.m,
    },
    2: {
      fontSize: theme.fontSizes.body.s,
      lineHeight: theme.lineHeights.s,
    },
  }

  return (
    <Text
      {...props}
      fontSize={levels[level].fontSize}
      lineHeight={levels[level].lineHeight}
      fontWeight={theme.fontWeights[weight]}
      color={color || theme.colors.text.black}
    >
      {children}
    </Text>
  )
}

Body.defaultProps = {
  level: 1,
  weight: 'regular',
}

export default Body
